import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  kuud

  <span style="color:#85c1dc;">**FI:**</span>
  

  <span style="color:#85c1dc;">**RU:**</span>
  мес.
*/
/* @__NO_SIDE_EFFECTS__ */
const m786 = msg(
  mf`kuud`,
  mf``,
  mf`мес.`,
)

export { m786 as 'w.menu.jm.kuud' }