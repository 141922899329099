import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kas sul on soovituskood?

  <span style="color:#85c1dc;">**FI:**</span>
  Onko sinulla asiakaskoodi?

  <span style="color:#85c1dc;">**RU:**</span>
  У вас есть реферальный код?
*/
/* @__NO_SIDE_EFFECTS__ */
const m83 = msg(
  mf`Kas sul on soovituskood?`,
  mf`Onko sinulla asiakaskoodi?`,
  mf`У вас есть реферальный код?`,
)

export { m83 as 'w.register.recommendation_code_question' }