import { useLocalStorage } from '@vueuse/core'

export const useEmployeeMode = () => {
  const employeeMode = useLocalStorage('isEmployeeMode', false)

  const toggleEmployeeMode = () => {
    employeeMode.value = !employeeMode.value
  }

  return {
    isEmployeeMode: employeeMode,
    toggleEmployeeMode,
  }
}
