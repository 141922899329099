import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Minu konto

  <span style="color:#85c1dc;">**FI:**</span>
  Tilini

  <span style="color:#85c1dc;">**RU:**</span>
  Мой профиль
*/
/* @__NO_SIDE_EFFECTS__ */
const m903 = msg(
  mf`Minu konto`,
  mf`Tilini`,
  mf`Мой профиль`,
)

export { m903 as 'w.menu.minukonto' }