import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Vaata kõiki

  <span style="color:#85c1dc;">**FI:**</span>
  Katso kaikki

  <span style="color:#85c1dc;">**RU:**</span>
  Смотреть все
*/
/* @__NO_SIDE_EFFECTS__ */
const m454 = msg(
  mf`Vaata kõiki`,
  mf`Katso kaikki`,
  mf`Смотреть все`,
)

export { m454 as 'w.search.view-all' }