import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Unustasid salasõna?

  <span style="color:#85c1dc;">**FI:**</span>
  Unohdit salasanan?

  <span style="color:#85c1dc;">**RU:**</span>
  Забыл пароль?
*/
/* @__NO_SIDE_EFFECTS__ */
const m600 = msg(
  mf`Unustasid salasõna?`,
  mf`Unohdit salasanan?`,
  mf`Забыл пароль?`,
)

export { m600 as 'w.klient.unustasid.parooli' }