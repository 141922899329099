import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Turundusega seotud küpsiseid kasutatakse külastajatele jälgimiseks eri veebilehtedel. Selle eesmärk on näidata kliendile reklaame, mis on kasutajale asjakohased ja huvitavad ning seega avaldajatele ja kolmanda osapoole reklaamijatele väärtuslikumad.

  <span style="color:#85c1dc;">**FI:**</span>
  Markkinointiin liittyviä evästeitä käytetään vierailijoiden seuraamiseen eri verkkosivuilla. Sen tavoitteena on näyttää asiakkaalle mainoksia, jotka ovat osuvia ja kiinnostavia käyttäjää kohtaan ja siten arvokkaampia julkaisijoille ja kolmannen osapuolen mainostajille.

  <span style="color:#85c1dc;">**RU:**</span>
  Маркетинговые файлы cookie используются для отслеживания посетителей на различных веб-страницах. Его цель – показывать клиенту рекламу, которая актуальна и интересна пользователю и, следовательно, более ценна для издателей и сторонних рекламодателей.
*/
/* @__NO_SIDE_EFFECTS__ */
const m270 = msg(
  mf`Turundusega seotud küpsiseid kasutatakse külastajatele jälgimiseks eri veebilehtedel. Selle eesmärk on näidata kliendile reklaame, mis on kasutajale asjakohased ja huvitavad ning seega avaldajatele ja kolmanda osapoole reklaamijatele väärtuslikumad.`,
  mf`Markkinointiin liittyviä evästeitä käytetään vierailijoiden seuraamiseen eri verkkosivuilla. Sen tavoitteena on näyttää asiakkaalle mainoksia, jotka ovat osuvia ja kiinnostavia käyttäjää kohtaan ja siten arvokkaampia julkaisijoille ja kolmannen osapuolen mainostajille.`,
  mf`Маркетинговые файлы cookie используются для отслеживания посетителей на различных веб-страницах. Его цель – показывать клиенту рекламу, которая актуальна и интересна пользователю и, следовательно, более ценна для издателей и сторонних рекламодателей.`,
)

export { m270 as 'w.cookies.customize.marketing.text' }