import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Liitu ja kingime sulle kohe 20 €

  <span style="color:#85c1dc;">**FI:**</span>
  Rekisteröidy ja saat heti 20 €

  <span style="color:#85c1dc;">**RU:**</span>
  Присоединяйся и получите 20 € сразу
*/
/* @__NO_SIDE_EFFECTS__ */
const m1038 = msg(
  mf`Liitu ja kingime sulle kohe 20 €`,
  mf`Rekisteröidy ja saat heti 20 €`,
  mf`Присоединяйся и получите 20 € сразу`,
)

export { m1038 as 'w.header.menu.new-customer-benefit' }