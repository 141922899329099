export const useHotjar = sharedComposable(() => {
  const {
    public: {
      scripts: {
        hotjar: { id: hotjarId },
      },
    },
  } = useRuntimeConfig()
  const { analyticalCookieConsentScriptTrigger } = useCookieConsent()

  return useScriptHotjar({
    id: hotjarId,
    scriptOptions: {
      trigger: analyticalCookieConsentScriptTrigger,
    },
  })
})
