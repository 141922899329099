import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  /info/tarneaeg\_ja\_transport

  <span style="color:#85c1dc;">**FI:**</span>
  

  <span style="color:#85c1dc;">**RU:**</span>
  /info/srok\_postavki\_i\_transport
*/
/* @__NO_SIDE_EFFECTS__ */
const m1113 = msg(
  mf`/info/tarneaeg_ja_transport`,
  mf``,
  mf`/info/srok_postavki_i_transport`,
)

export { m1113 as 'link.venipak.href' }