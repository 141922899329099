<template>
  <BurgerMenuView
    v-model:active-menu="activeMenu"
    class="flex flex-col"
    :menu-name="props.menuName"
    :has-top="childActiveMenu === ''"
    v-bind="$attrs"
    :breadcrumbs="props.breadcrumbs"
  >
    <div v-show="childActiveMenu === ''" class="overflow-auto">
      <NuxtLink v-if="props.item?.url && !props.hideTopLevelLink" :to="props.item.url" class="block border-b border-border-light p-5 text-button-xl font-medium text-text-strong">
        <div class="pl-8">
          <span class="align-text-top" :class="allLabel.length === 1 ? 'mr-2' : 'mr-1.5'">{{ allLabel[0] }}</span>
          <span class="inline-block">
            <span v-if="allLabel.length > 1" class="mr-2 align-text-top">{{ allLabel[1] }}</span>
            <Icon class="size-6 align-text-top" name="arrow-right" />
          </span>
        </div>
      </NuxtLink>
      <div class="py-3">
        <BurgerMenuLink
          v-for="(burgerItem, index) in props.children"
          :key="`menu-item-${index}`"
          v-model:active-menu="childActiveMenu"
          class="px-5 py-3"
          :menu-name="burgerItem.name"
          :url="burgerItem.url"
          :opens-menu="!!(burgerItem.children && burgerItem.children.length > 0)"
        >
          <div class="flex items-center justify-between text-button-xl font-medium" :class="{ 'pl-8': !burgerItem.icon }">
            <span class="flex items-center text-left">
              <Icon v-if="burgerItem.icon" :name="burgerItem.icon" class="mr-2 size-6 text-icon-active" />
              {{ burgerItem.name }}
            </span>
            <Icon v-if="burgerItem.children && burgerItem.children.length > 0" name="arrow-right" class="size-6" />
          </div>
        </BurgerMenuLink>
      </div>
      <div v-if="headerSpecialLinkTree" class="border-t border-border-light py-3">
        <BurgerMenuLink
          v-model:active-menu="childActiveMenu"
          class="px-5 py-3"
          :menu-name="headerSpecialLinkTree.name"
          :url="headerSpecialLinkTree.url"
          :opens-menu="headerSpecialLinkTree.links && headerSpecialLinkTree.links.length > 0"
        >
          <div class="flex items-center justify-between text-button-xl font-medium" :class="{ 'pl-8': !headerSpecialLinkTree.icon }">
            <span class="mr-2 flex items-center">
              <Icon v-if="headerSpecialLinkTree.icon" :name="headerSpecialLinkTree.icon" class="size-6 text-icon-active" />
              {{ headerSpecialLinkTree.name }}
            </span>
            <Icon v-if="headerSpecialLinkTree.links && headerSpecialLinkTree.links.length > 0" name="arrow-right" class="size-6" />
          </div>
        </BurgerMenuLink>
      </div>
    </div>
    <BurgerMenuNavigationView
      v-for="(burgerItem, index) in props.children"
      :key="`menu-item-${index}`"
      v-model:active-menu="childActiveMenu"
      class="absolute inset-0 flex flex-col"
      :menu-name="burgerItem.name"
      :children="burgerItem.children"
      :breadcrumbs="props.menuName"
      :item="burgerItem"
    />
    <BurgerMenuNavigationView
      v-if="headerSpecialLinkTree"
      v-model:active-menu="childActiveMenu"
      class="absolute inset-0 flex flex-col"
      :menu-name="headerSpecialLinkTree.name"
      :children="headerSpecialLinkTree.links"
      :breadcrumbs="props.menuName"
      :item="headerSpecialLinkTree"
    />
  </BurgerMenuView>
</template>

<script setup lang="ts">
import BurgerMenuView from '~/components/header/BurgerMenuView.vue'
import BurgerMenuLink from '~/components/header/BurgerMenuLink.vue'
import Icon from '~/components/icon/Icon.vue'
import * as t from '~/messages'
import type { HeaderLink } from '~/types/header-link'
import type { HeaderLinkChild } from '~/types/header-link-child'
import type { HeaderSpecialLinkTree } from '~/types/header-special-link-tree'

const props = withDefaults(
  defineProps<{
    activeMenu: string
    menuName: string
    hideTopLevelLink?: boolean
    children?: HeaderLinkChild[] | null
    item?: HeaderLink | HeaderLinkChild
    breadcrumbs?: string
  }>(),
  {
    hideTopLevelLink: false,
    children: undefined,
    item: undefined,
    breadcrumbs: '',
  }
)

const headerSpecialLinkTree = computed<HeaderSpecialLinkTree | null>(() => {
  if (props.item && 'headerSpecialLinkTree' in props.item) {
    return props.item.headerSpecialLinkTree
  }

  return null
})

const childActiveMenu = ref('')

const emit = defineEmits<{
  'update:activeMenu': [menuName: string]
}>()

const allLabel = computed(() => {
  // TODO: Improve on this return value
  if (!props.item) {
    return ['']
  }

  const label = ('viewAllName' in props.item && props.item.viewAllName ? props.item.viewAllName : t['w.header.menu.view-all']()).replace('%s', props.item.name.toLowerCase())

  const split = label.split(/\s(?=\S*$)/)

  return split.length > 1 ? [split[0], split[1]] : [label]
})

const activeMenu = computed({
  get() {
    return props.activeMenu
  },
  set(newValue) {
    emit('update:activeMenu', newValue)
  },
})
</script>
