import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Eelistusega seotud küpsised võimaldavad veebilehel meeles pidada teavet, mis muudab seda, kuidas veebileht töötab või välja näeb - st näiteks teie eelistatud kasutuskeelt või asukohta.

  <span style="color:#85c1dc;">**FI:**</span>
  Asetusevästeiden avulla verkkosivusto muistaa tiedot, jotka muuttavat verkkosivuston toimintaa tai ulkoasua – esimerkiksi haluamasi kielen tai sijainnin.

  <span style="color:#85c1dc;">**RU:**</span>
  Файлы cookie, связанные с предпочтениями, позволяют веб-сайту запоминать информацию, которая меняет работу или внешний вид веб-сайта, например, предпочитаемый вами язык или местоположение.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1489 = msg(
  mf`Eelistusega seotud küpsised võimaldavad veebilehel meeles pidada teavet, mis muudab seda, kuidas veebileht töötab või välja näeb - st näiteks teie eelistatud kasutuskeelt või asukohta.`,
  mf`Asetusevästeiden avulla verkkosivusto muistaa tiedot, jotka muuttavat verkkosivuston toimintaa tai ulkoasua – esimerkiksi haluamasi kielen tai sijainnin.`,
  mf`Файлы cookie, связанные с предпочтениями, позволяют веб-сайту запоминать информацию, которая меняет работу или внешний вид веб-сайта, например, предпочитаемый вами язык или местоположение.`,
)

export { m1489 as 'w.cookies.customize.functional.text' }