import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Menüü

  <span style="color:#85c1dc;">**FI:**</span>
  Valikko

  <span style="color:#85c1dc;">**RU:**</span>
  Меню
*/
/* @__NO_SIDE_EFFECTS__ */
const m398 = msg(
  mf`Menüü`,
  mf`Valikko`,
  mf`Меню`,
)

export { m398 as 'w.header.menu.root-menu-name' }