export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const headers = useRequestHeaders()

    const api = $fetch.create({
      baseURL: '/api',
      redirect: 'error',
      headers,
    })

    return {
      provide: {
        api,
      },
    }
  },
})
