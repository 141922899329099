import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Mugav ja turvaline ostukogemus

  <span style="color:#85c1dc;">**FI:**</span>
  Kätevä ja turvallinen ostoskokemus

  <span style="color:#85c1dc;">**RU:**</span>
  Удобный и безопасный опыт покупок
*/
/* @__NO_SIDE_EFFECTS__ */
const m213 = msg(
  mf`Mugav ja turvaline ostukogemus`,
  mf`Kätevä ja turvallinen ostoskokemus`,
  mf`Удобный и безопасный опыт покупок`,
)

export { m213 as 'w.module.value_propositions.title' }