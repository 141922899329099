import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Palun täida kõik kohustuslikud väljad

  <span style="color:#85c1dc;">**FI:**</span>
  Täytä kaikki kentät

  <span style="color:#85c1dc;">**RU:**</span>
  Пожалуйста, заполните все обязательные поля
*/
/* @__NO_SIDE_EFFECTS__ */
const m173 = msg(
  mf`Palun täida kõik kohustuslikud väljad`,
  mf`Täytä kaikki kentät`,
  mf`Пожалуйста, заполните все обязательные поля`,
)

export { m173 as 'w.basket.after_payment.submit_button_required_fields' }