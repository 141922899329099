<template>
  <div class="flex flex-col md:flex-row md:items-center">
    <img class="mx-auto mb-6 hidden max-w-40 sm:block md:mb-0 md:max-w-1/2 md:basis-1/2 md:pr-5" src="~/assets/illustrations/login.svg" />
    <div class="relative h-fit md:max-w-1/2 md:basis-1/2 md:pl-5">
      <div class="w-full pb-8 text-center md:pb-7">
        <div class="text-h2 font-light">{{ t['w.global.login']() }}</div>
        <div class="mt-2 text-base">
          <span v-if="props.introductionText">{{ props.introductionText }}</span>
          <span v-else>{{ t['w.login.welcome']() }}</span
          >&nbsp;
          <button class="text-text-strong underline hover:no-underline" @click="emit('registerClick')">{{ t['w.login.register-account']() }}</button>
        </div>
      </div>
      <LoginForm :is-forwarding="false" class="w-full md:pt-0" />
    </div>
  </div>
</template>

<script setup lang="ts">
import LoginForm from '~/components/login/LoginForm.vue'
import * as t from '~/messages'

const props = withDefaults(
  defineProps<{
    introductionText: string | null
  }>(),
  {
    introductionText: null,
  }
)

const emit = defineEmits<{
  registerClick: []
}>()
</script>
