import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Luba küpsised

  <span style="color:#85c1dc;">**FI:**</span>
  Salli evästeet

  <span style="color:#85c1dc;">**RU:**</span>
  Разрешить файлы cookie
*/
/* @__NO_SIDE_EFFECTS__ */
const m1396 = msg(
  mf`Luba küpsised`,
  mf`Salli evästeet`,
  mf`Разрешить файлы cookie`,
)

export { m1396 as 'w.cookies.button.accept' }