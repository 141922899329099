function scrollToElement(element: HTMLElement, forced: boolean = false) {
  const currentScroll = window.scrollY || 0
  const elementTop = getYPosition(element)

  if (currentScroll > elementTop || forced) {
    window.scrollTo({
      top: elementTop,
      behavior: 'smooth',
    })
  }
}

// https://stackoverflow.com/a/50310297
function getYPosition(element: HTMLElement | null) {
  if (element) {
    return window.scrollY + element.getBoundingClientRect().top
  }

  return 0
}

export { scrollToElement }
