import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  SmartPost pakiautomaat

  <span style="color:#85c1dc;">**FI:**</span>
  Toimitus Postin pakettiautomaattiin

  <span style="color:#85c1dc;">**RU:**</span>
  Терминал SmartPost
*/
/* @__NO_SIDE_EFFECTS__ */
const m1320 = msg(
  mf`SmartPost pakiautomaat`,
  mf`Toimitus Postin pakettiautomaattiin`,
  mf`Терминал SmartPost`,
)

export { m1320 as 'link.smartpost.alt' }