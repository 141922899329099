<template>
  <ol class="flex flex-wrap">
    <li
      v-for="(item, index) of elements"
      :key="index"
      class="items-center"
      :class="{
        'hidden md:inline-flex': index < alwaysVisibleStartIndex,
        'inline-flex': index >= alwaysVisibleStartIndex,
      }"
    >
      <span v-if="index > 0" class="mx-1" :class="{ 'hidden md:block': index <= alwaysVisibleStartIndex }"> / </span>
      <component
        :is="index === elements.length - 1 ? 'span' : NuxtLink"
        class="whitespace-nowrap text-base"
        :class="{
          'cursor-pointer hover:text-text-strong': index < elements.length - 1,
          'text-text-disabled': index === elements.length - 1,
        }"
        :to="item.url"
      >
        {{ item.name }}
      </component>
    </li>
  </ol>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

interface Breadcrumb {
  name: string
  url: string
}

const ALWAYS_VISIBLE_ELEMENTS = 2

const alwaysVisibleStartIndex = computed(() => {
  return Math.max(props.elements.length - ALWAYS_VISIBLE_ELEMENTS, 0)
})

const props = withDefaults(
  defineProps<{
    elements?: Breadcrumb[]
  }>(),
  {
    elements: () => [],
  }
)
</script>
