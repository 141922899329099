import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Vajalikud

  <span style="color:#85c1dc;">**FI:**</span>
  Välttämättömät

  <span style="color:#85c1dc;">**RU:**</span>
  Необходимые
*/
/* @__NO_SIDE_EFFECTS__ */
const m99 = msg(
  mf`Vajalikud`,
  mf`Välttämättömät`,
  mf`Необходимые`,
)

export { m99 as 'w.cookies.customize.mandatory.title' }