import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Logi sisse

  <span style="color:#85c1dc;">**FI:**</span>
  Kirjaudu sisään

  <span style="color:#85c1dc;">**RU:**</span>
  Войти
*/
/* @__NO_SIDE_EFFECTS__ */
const m1975 = msg(
  mf`Logi sisse`,
  mf`Kirjaudu sisään`,
  mf`Войти`,
)

export { m1975 as 'w.global.login' }