import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sulge otsing

  <span style="color:#85c1dc;">**FI:**</span>
  Sulje haku

  <span style="color:#85c1dc;">**RU:**</span>
  Закрыть поиск
*/
/* @__NO_SIDE_EFFECTS__ */
const m673 = msg(
  mf`Sulge otsing`,
  mf`Sulje haku`,
  mf`Закрыть поиск`,
)

export { m673 as 'search.close' }