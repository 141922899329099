import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Logi välja

  <span style="color:#85c1dc;">**FI:**</span>
  Kirjaudu ulos

  <span style="color:#85c1dc;">**RU:**</span>
  Выйди
*/
/* @__NO_SIDE_EFFECTS__ */
const m1583 = msg(
  mf`Logi välja`,
  mf`Kirjaudu ulos`,
  mf`Выйди`,
)

export { m1583 as 'w.menu.logout' }