import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  või

  <span style="color:#85c1dc;">**FI:**</span>
  tai

  <span style="color:#85c1dc;">**RU:**</span>
  или
*/
/* @__NO_SIDE_EFFECTS__ */
const m882 = msg(
  mf`või`,
  mf`tai`,
  mf`или`,
)

export { m882 as 'w.login.additional-delim' }