type Content = { id: string; quantity: number }

export const useMetaPixel = sharedComposable(() => {
  const {
    public: {
      scripts: {
        metaPixel: { id: metaPixelId },
      },
    },
  } = useRuntimeConfig()

  const { marketingCookieConsentScriptTrigger } = useCookieConsent()

  const { proxy } = useScriptMetaPixel({
    id: metaPixelId,
    scriptOptions: {
      trigger: marketingCookieConsentScriptTrigger,
    },
  })

  return {
    proxy,
    sendProductView: (productId: Content['id']) =>
      proxy.fbq('track', 'ViewContent', {
        content_ids: [productId],
        content_type: 'product',
      }),
    sendAddToCart: (content: Content) =>
      proxy.fbq('track', 'AddToCart', {
        content_type: 'product',
        contents: [content],
      }),
    sendPurchase: (orderValue: number, orderCurrency: string, contents: Content[]) =>
      proxy.fbq('track', 'Purchase', {
        value: orderValue,
        currency: orderCurrency,
        contents,
      }),
  }
})

export const useMetaPixelAppInit = () => {
  const { proxy } = useMetaPixel()

  return useScriptEventPage(() => {
    proxy.fbq('track', 'PageView')
  })
}
