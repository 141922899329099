import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Püsikliendiks registreerudes kinnitan, et olen tutvunud ja &lt;a href="/info/on24-üldtingimused" target="\_blank"&gt;nõustun kasutustingimustega.&lt;/a&gt;

  <span style="color:#85c1dc;">**FI:**</span>
  ON24-asiakkaaksi liittyessä vahvistan, että olen tutustunut ja &lt;a href="/info/on24-verkkokaupan\_kaeyttoeehdot" target="\_blank&gt; ja hyväksyn käyttäjäehdot.&lt;/a&gt;

  <span style="color:#85c1dc;">**RU:**</span>
  Регистрируясь как постоянный клиент, я подтверждаю, что прочитал и &lt;a href="/info/общие\_условия\_on24" target="\_blank"&gt;согласен с условиями использования.&lt;/a&gt;
*/
/* @__NO_SIDE_EFFECTS__ */
const m1609 = msg(
  mf`Püsikliendiks registreerudes kinnitan, et olen tutvunud ja <a href="/info/on24-üldtingimused" target="_blank">nõustun kasutustingimustega.</a>`,
  mf`ON24-asiakkaaksi liittyessä vahvistan, että olen tutustunut ja <a href="/info/on24-verkkokaupan_kaeyttoeehdot" target="_blank> ja hyväksyn käyttäjäehdot.</a>`,
  mf`Регистрируясь как постоянный клиент, я подтверждаю, что прочитал и <a href="/info/общие_условия_on24" target="_blank">согласен с условиями использования.</a>`,
)

export { m1609 as 'w.register.terms' }