import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Loo omale konto

  <span style="color:#85c1dc;">**FI:**</span>
  Avaa oma ON24-tili

  <span style="color:#85c1dc;">**RU:**</span>
  Создайте свой аккаунт
*/
/* @__NO_SIDE_EFFECTS__ */
const m747 = msg(
  mf`Loo omale konto`,
  mf`Avaa oma ON24-tili`,
  mf`Создайте свой аккаунт`,
)

export { m747 as 'w.register.title' }