import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Ava menüü

  <span style="color:#85c1dc;">**FI:**</span>
  Avaa valikko

  <span style="color:#85c1dc;">**RU:**</span>
  undefined
*/
/* @__NO_SIDE_EFFECTS__ */
const m881 = msg(
  mf`Ava menüü`,
  mf`Avaa valikko`,
  mf`undefined`,
)

export { m881 as 'w.header.menu.open-menu' }