import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sulge

  <span style="color:#85c1dc;">**FI:**</span>
  Sulje

  <span style="color:#85c1dc;">**RU:**</span>
  Закрыть
*/
/* @__NO_SIDE_EFFECTS__ */
const m385 = msg(
  mf`Sulge`,
  mf`Sulje`,
  mf`Закрыть`,
)

export { m385 as 'w.modal.close' }