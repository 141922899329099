import type { RouterConfig } from '@nuxt/schema'
import type { RouteRecordRaw } from 'vue-router'

export default <RouterConfig>{
  strict: true,
  sensitive: true,
  routes: (_routes) => {
    const {
      public: { languageId },
    } = useRuntimeConfig()

    const ee: RouteRecordRaw[] = [
      {
        path: '/:name(beauty|ilukaubad|children|lastemaailm|interior|sisustus)',
        redirect: '/',
      },
      {
        path: '/:name(ruumid|outlet)',
        component: () => import('~/components/rooms/RoomsHall.vue'),
      },
      {
        name: 'order-afterpayment',
        path: '/order/afterpayment',
        meta: {
          layout: 'checkout',
        },
        component: () => import('~/components/order/AfterPayment.vue'),
      },
    ]

    const fi: RouteRecordRaw[] = [
      {
        path: '/:name(beauty|kauneus_ja_kosmetiikka|children|lasten_maailma|interior)',
        redirect: '/',
      },
      {
        path: '/:name(huoneet|outlet)',
        component: () => import('~/components/rooms/RoomsHall.vue'),
      },
      {
        name: 'order-avarda',
        path: '/order/avarda',
        meta: {
          layout: 'checkout',
        },
        component: () => import('~/components/order/Avarda.vue'),
      },
    ]

    const ru: RouteRecordRaw[] = [
      {
        path: '/:name(beauty|children|interior)',
        redirect: '/',
      },
      {
        path: '/:name(комнаты|aутлет)',
        component: () => import('~/components/rooms/RoomsHall.vue'),
      },
      {
        name: 'order-afterpayment',
        path: '/order/afterpayment',
        meta: {
          layout: 'checkout',
        },
        component: () => import('~/components/order/AfterPayment.vue'),
      },
    ]

    return [
      _routes,
      {
        name: 'catchall',
        path: '/:catchall(.*)*',
        component: () => import('~/components/hall/Hall.vue'),
      },
      {
        name: 'hall',
        path: '/c/:id()/:slug()',
        component: () => import('~/components/hall/Hall.vue'),
      },
      {
        path: '/account',
        meta: { middleware: ['auth'] },
        component: () => import('~/components/account/Account.vue'),
        children: [
          {
            name: 'account',
            path: '',
            redirect: '/account/main',
          },
          {
            name: 'account-account-balance',
            path: 'account-balance',
            component: () => import('~/components/account/AccountBalance.vue'),
          },
          {
            name: 'account-main',
            path: 'main',
            component: () => import('~/components/account/AccountDetails.vue'),
          },
          {
            name: 'account-order-history',
            path: 'order-history',
            component: () => import('~/components/account/OrderHistory.vue'),
          },
          {
            name: 'account-wishlist',
            path: 'wishlist',
            component: () => import('~/components/account/AccountWishlistPage.vue'),
          },
        ],
      },
      {
        name: 'index',
        path: '/',
        component: () => import('~/components/index/Index.vue'),
      },
      {
        name: 'info',
        path: '/info/:slug()?',
        component: () => import('~/components/info/Info.vue'),
      },
      {
        name: 'login',
        path: '/login',
        component: () => import('~/components/login/LoginPage.vue'),
      },
      {
        name: 'order-basket',
        path: '/order/basket',
        component: () => import('~/components/order/Basket.vue'),
      },
      {
        name: 'order-check',
        path: '/order/check',
        component: () => import('~/components/order/Check.vue'),
      },
      {
        name: 'order-payment',
        path: '/order/payment',
        meta: {
          layout: 'checkout',
        },
        component: () => import('~/components/order/Payment.vue'),
      },
      {
        name: 'order-transport',
        path: '/order/transport',
        meta: {
          layout: 'checkout',
        },
        component: () => import('~/components/order/Transport.vue'),
      },
      {
        name: 'product',
        path: '/p/:id([1-9][0-9]*)/:slug()?',
        component: () => import('~/components/product/Product.vue'),
      },
      {
        name: 'brand-hall',
        path: '/prods/:cat(interior|beauty|children)/brand/:slug()',
        component: () => import('~/components/hall/Hall.vue'),
      },
      {
        name: 'brand-hall-legacy',
        path: '/prods/brand/:slug()',
        component: () => import('~/components/hall/Hall.vue'),
      },
      {
        name: 'brand-list',
        path: '/prods/:cat(interior|beauty|children)/brand',
        component: () => import('~/components/brand/BrandList.vue'),
      },
      {
        name: 'brand-list-legacy',
        path: '/prods/brand',
        component: () => import('~/components/brand/BrandList.vue'),
      },
      {
        name: 'new-products-hall',
        path: '/prods/:cat(interior|beauty|children)/new/:id([1-9][0-9]*)/:slug()?',
        component: () => import('~/components/hall/NewProductsHall.vue'),
      },
      {
        name: 'new-products-index',
        path: '/prods/:cat(interior|beauty|children)/new',
        component: () => import('~/components/hall/NewProductsHall.vue'),
      },
      {
        name: 'onsale-products-hall',
        path: '/prods/:cat(interior|beauty|children)/onsale/:id([1-9][0-9]*)/:slug()?',
        component: () => import('~/components/hall/DiscountProductsHall.vue'),
      },
      {
        name: 'onsale-products-hall-index',
        path: '/prods/:cat(interior|beauty|children)/onsale',
        component: () => import('~/components/hall/DiscountProductsHall.vue'),
      },
      {
        name: 'prods-last',
        path: '/prods/last',
        component: () => import('~/components/hall/LastViewedProductsHall.vue'),
      },
      {
        name: 'register',
        path: '/register',
        component: () => import('~/components/register/Register.vue'),
      },
      {
        name: 'search',
        path: '/search',
        component: () => import('~/components/search/Search.vue'),
      },
      {
        name: 'special-slug',
        path: '/special/:slug()',
        component: () => import('~/components/hall/SpecialHall.vue'),
      },
      {
        name: 'wishlist-slug',
        path: '/wishlist/:slug()',
        component: () => import('~/components/account/Wishlist.vue'),
      },
      ...(languageId === 1 ? ee : []),
      ...(languageId === 2 ? fi : []),
      ...(languageId === 3 ? ru : []),
    ]
  },
}
