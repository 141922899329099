<template>
  <ul v-if="pages > 1" class="my-4 flex justify-center gap-1 sm:gap-2">
    <!-- Previous -->
    <li v-if="currentPage !== 1">
      <NuxtLink
        class="btn-secondary btn-rounded flex size-10 items-center justify-center"
        :to="{ path: currentPath, query: paramsForPage(currentPage - 1) }"
        @click="emit('navigation')"
      >
        <Icon class="size-5 rotate-90" name="chevron-down" />
      </NuxtLink>
    </li>
    <li v-else>
      <span class="flex size-10 items-center justify-center rounded-full border border-border-disabled text-text-disabled">
        <Icon class="size-5 rotate-90" name="chevron-down" />
      </span>
    </li>

    <li v-for="(item, index) in pageButtons" :key="index" :class="item.classes">
      <template v-if="item.type === 'button'">
        <span v-if="item.number === currentPage" class="flex size-10 shrink-0 items-center justify-center rounded-full bg-background-none-active text-button-m text-text-strong">
          {{ item.number }}
        </span>
        <NuxtLink
          v-else
          class="flex size-10 shrink-0 items-center justify-center rounded-full text-button-m transition-colors hover:bg-background-none-hover"
          :to="{ path: currentPath, query: paramsForPage(item.number) }"
          @click="emit('navigation')"
        >
          {{ item.number }}
        </NuxtLink>
      </template>
      <span v-else-if="item.type === 'ellipsis'" class="flex size-10 shrink-0 items-center justify-center text-button-m text-text-disabled">...</span>
    </li>

    <!-- Next -->
    <li v-if="currentPage !== pages">
      <NuxtLink
        class="btn-secondary btn-rounded flex size-10 items-center justify-center"
        :to="{ path: currentPath, query: paramsForPage(currentPage + 1) }"
        @click="emit('navigation')"
      >
        <Icon class="size-5 rotate-90" name="chevron-up" />
      </NuxtLink>
    </li>
    <li v-else>
      <span class="flex size-10 items-center justify-center rounded-full border border-border-disabled text-text-disabled">
        <Icon class="size-5 rotate-90" name="chevron-up" />
      </span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { pageNumbers } from './page-numbers'

const props = defineProps<{
  currentPath: string
  currentPage: number
  pages: number
  param: string
}>()

const emit = defineEmits<{
  navigation: []
}>()

const route = useRoute()

const paramsForPage = (pageNumber: number) => {
  const { [props.param]: _, ...query } = route.query

  if (pageNumber <= 1) {
    return query
  }

  return {
    [props.param]: pageNumber.toString(),
    ...query,
  }
}

const pageButtons = computed(() => pageNumbers(props.currentPage, props.pages))
</script>
