import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Liitu Facebook-i abil

  <span style="color:#85c1dc;">**FI:**</span>
  Liity Facebookin kautta

  <span style="color:#85c1dc;">**RU:**</span>
  Присоединяйтесь через Facebook
*/
/* @__NO_SIDE_EFFECTS__ */
const m1908 = msg(
  mf`Liitu Facebook-i abil`,
  mf`Liity Facebookin kautta`,
  mf`Присоединяйтесь через Facebook`,
)

export { m1908 as 'w.global.register_facebook' }