<template>
  <form @submit.prevent="login">
    <InfoBanner v-if="facebookLoginErrorStore.loginErrorMessage" :text="facebookLoginErrorStore.loginErrorMessage" state="attention" class="mb-4" />

    <div>
      <div>
        <Input
          v-model="formEmail"
          name="email"
          :placeholder="t['w.login.placeholder.email']()"
          :label="t['w.ostukorv.epost']()"
          autocomplete="email"
          type="email"
          field-size="small"
          required
          hide-label
        />
      </div>

      <div class="mt-2">
        <Input
          v-model="formPassword"
          name="password"
          :placeholder="t['w.login.placeholder.password']()"
          :label="t['w.klient.parool']()"
          autocomplete="password"
          type="password"
          field-size="small"
          required
          hide-label
        />
      </div>

      <div v-if="!success && message && props.showMessages">
        <p class="mt-2 pl-4 text-sm text-text-attention" v-html="message" />
      </div>

      <div v-if="success && message && props.showMessages">
        <p class="mt-2 pl-4 text-sm text-text-strong" v-html="message" />
      </div>

      <div class="mt-4 flex justify-between text-base">
        <CheckboxInput v-model="formRemember" size="small" :label="t['w.klient.remember']()">{{ t['w.klient.remember']() }}</CheckboxInput>
        <button type="button" class="text-link-strong underline hover:no-underline" @click="gotoForgotPassword">{{ t['w.klient.unustasid.parooli']() }}</button>
      </div>

      <div class="mt-4">
        <Button :is-loading="isLoading" class="btn w-full rounded-button px-6 py-3 text-button-m font-medium" type="submit">{{ t['w.global.login']() }}</Button>
      </div>

      <div v-if="additionalLogins" class="mb-4">
        <div
          class="relative my-4 flex justify-center text-base before:absolute before:inset-x-0 before:top-1/2 before:h-px before:-translate-y-1/2 before:border-b before:border-border-medium"
        >
          <span class="relative inline-block bg-background px-5">{{ t['w.login.additional-delim']() }}</span>
        </div>

        <LoginFacebook is-large />
        <LoginGoogle v-if="false" is-large class="mt-4" />
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useLoginModalStore } from '~/stores/login-modal'

import Input from '~/components/input/Input.vue'
import CheckboxInput from '~/components/input/CheckboxInput.vue'
import LoginFacebook from '~/components/login/buttons/LoginFacebook.vue'
import LoginGoogle from '~/components/login/buttons/LoginGoogle.vue'
import InfoBanner from '~/components/info-banner/InfoBanner.vue'

import type { FetchError } from 'ofetch'
import { useSuccessfulRegistrationEmail } from '~/composables/successful-registration-email'
import type { LoginSubmitResponse } from '~/types/on24-klient'
import * as t from '~/messages'

const facebookLoginErrorStore = useFacebookLoginErrorStore()

const props = withDefaults(
  defineProps<{
    additionalLogins?: boolean
    isForwarding?: boolean
    showMessages?: boolean
  }>(),
  {
    additionalLogins: true,
    isForwarding: true,
    showMessages: true,
  }
)

const registrationEmail = useSuccessfulRegistrationEmail().get()
let success = !!registrationEmail

const formEmail = ref<string>(registrationEmail || '')
const formPassword = ref<string>('')
const formRemember = ref<boolean>(true)
const message = ref<string | null>(success ? t['w.register.sucess']() : null)
const isLoading = ref<boolean>(false)

const loginModalStore = useLoginModalStore()

const login = async () => {
  if (!formEmail.value || !formPassword.value) {
    return
  }

  facebookLoginErrorStore.clearErrors()

  isLoading.value = true

  try {
    const response = await $fetch<LoginSubmitResponse>('/api/login', {
      method: 'POST',
      body: {
        email: formEmail.value,
        password: formPassword.value,
        remember: formRemember.value,
      },
    })

    if (response) {
      success = response.success
      message.value = response.message

      if (response.success) {
        loginModalStore.useQueuedWishlistItem()
      } else {
        isLoading.value = false
      }

      if (props.isForwarding) {
        if (response.success && response.forward) {
          window.location.href = response.forward
        }
      } else if (response.success) {
        window.location.reload()
      }
    }
  } catch (error) {
    const fetchError = error as FetchError<LoginSubmitResponse>

    success = false

    if (fetchError.data) {
      message.value = fetchError.data.message
      isLoading.value = false
    }
  }
}

const gotoForgotPassword = async () => {
  await navigateTo({
    path: t['w.login.forgot-password.url'](),
  })
  loginModalStore.close()
}

onBeforeUnmount(() => {
  facebookLoginErrorStore.clearLoginError()
})
</script>
