import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  /info/on24-üldtingimused

  <span style="color:#85c1dc;">**FI:**</span>
  /info/on24-verkkokaupan\_kaeyttoeehdot

  <span style="color:#85c1dc;">**RU:**</span>
  /info/общие\_условия\_on24
*/
/* @__NO_SIDE_EFFECTS__ */
const m1486 = msg(
  mf`/info/on24-üldtingimused`,
  mf`/info/on24-verkkokaupan_kaeyttoeehdot`,
  mf`/info/общие_условия_on24`,
)

export { m1486 as 'link.conditions.href' }