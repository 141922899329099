<template><render /></template>

<script setup lang="ts" generic="T extends string">
type Message<T extends string> = [T] extends [never]
  ? {
      (p?: Record<string, never>): string
      tokens(p?: Record<string, never>): readonly string[]
    }
  : {
      (p: Record<T, unknown>): string
      tokens<P extends Record<T, unknown>>(p: P & Record<Exclude<keyof P, T>, never>): readonly (string | P[keyof P])[]
    }

const props = defineProps<{
  message: Message<T>
}>()

const slots = defineSlots<Record<T, () => unknown>>()

const render = () => {
  return props.message.tokens(
    // @ts-expect-error correct type is not that relevant
    Object.fromEntries(Object.entries(slots).map(([slotName, slot]) => [slotName, slot ? h(slot) : null]))
  )
}
</script>
