import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sisesta ees- ja perekonnanimi

  <span style="color:#85c1dc;">**FI:**</span>
  Syötä etu- ja sukunimi

  <span style="color:#85c1dc;">**RU:**</span>
  Введите имя и фамилию
*/
/* @__NO_SIDE_EFFECTS__ */
const m1658 = msg(
  mf`Sisesta ees- ja perekonnanimi`,
  mf`Syötä etu- ja sukunimi`,
  mf`Введите имя и фамилию`,
)

export { m1658 as 'w.register.placeholder.full_name' }