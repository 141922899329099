<template>
  <FloatingLabelInputWrapper :label="label" :name="name" :required="required" :is-error="props.isError">
    <input
      :id="name"
      ref="inputElement"
      class="form-input mt-1 block w-full rounded border-border-medium focus:border-border-hover focus:ring focus:ring-border-light/50 md:text-base"
      :class="{
        'border-border-medium': !isError,
        'border-text-attention': isError,
        'bg-background-light-strong': readOnly,
      }"
      :type="type"
      :name="name"
      :value="modelValue"
      :size="size"
      :autocomplete="autocomplete"
      :required="required"
      :readonly="readOnly"
      :placeholder="placeholder"
      @input="input"
      @invalid="invalid"
      @focus="focus"
      @blur="blur"
      @keypress="validate"
    />
  </FloatingLabelInputWrapper>
</template>

<script setup lang="ts">
import FloatingLabelInputWrapper from './FloatingLabelInputWrapper.vue'
import * as t from '~/messages'

const props = withDefaults(
  defineProps<{
    type?: 'text' | 'email' | 'password'
    name: string
    label?: string
    required?: boolean
    modelValue?: string
    size?: string
    autocomplete?: string
    validation?: string
    isError?: boolean
    readOnly?: boolean
    placeholder?: string
  }>(),
  {
    type: 'text',
    label: undefined,
    required: false,
    modelValue: undefined,
    size: undefined,
    autocomplete: undefined,
    validation: undefined,
    isError: false,
    readOnly: false,
    placeholder: undefined,
  }
)

const inputElement = useTemplateRef('inputElement')

const focus = () => {
  emit('focus')
}

const blur = () => {
  emit('blur')
}

const emit = defineEmits<{
  'update:modelValue': [newValue: string]
  blur: []
  focus: []
}>()

const input = useSafeEvent<HTMLInputElement>((event) => {
  emit('update:modelValue', event.target.value)
  event.target.setCustomValidity('')
})

const invalid = useSafeEvent<HTMLInputElement>((event) => {
  if (props.required && (!props.modelValue || !props.modelValue.length)) {
    event.target.setCustomValidity(t['floating-input.required']())
  }
})

const validate = (event: KeyboardEvent) => {
  if (props.validation && !new RegExp(props.validation).test(event.key)) {
    return event.preventDefault()
  }
}

defineExpose({ inputElement })
</script>
