import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Saatsime Teie e-posti aadressile salasõna.&lt;br /&gt;Palun kontrollige oma saabunud e-maile.

  <span style="color:#85c1dc;">**FI:**</span>
  Lähetimme antamaasi sähköpostiosoitteeseen salasanan, jolla voit kirjautua ON24 Sisustustavarataloon kanta-asiakkaana. Ole ystävällinen ja tarkista saapuneet sähköpostisi.

  <span style="color:#85c1dc;">**RU:**</span>
  Пароль выслан на Ваш адрес электронной почты.&lt;br /&gt;Пожалуйста проверте входящии сообщения.
*/
/* @__NO_SIDE_EFFECTS__ */
const m851 = msg(
  mf`Saatsime Teie e-posti aadressile salasõna.<br />Palun kontrollige oma saabunud e-maile.`,
  mf`Lähetimme antamaasi sähköpostiosoitteeseen salasanan, jolla voit kirjautua ON24 Sisustustavarataloon kanta-asiakkaana. Ole ystävällinen ja tarkista saapuneet sähköpostisi.`,
  mf`Пароль выслан на Ваш адрес электронной почты.<br />Пожалуйста проверте входящии сообщения.`,
)

export { m851 as 'w.register.sucess' }