<template>
  <CollapsibleRoot
    class="relative rounded-xl border transition-[border]"
    :class="{
      'border-border-active': isSelected,
      'border-border-medium hover:border-border-hover': !isSelected,
    }"
    :open="isSelected"
  >
    <div class="relative flex items-center">
      <slot name="label" />
    </div>
    <CollapsibleContent
      v-if="!props.dropdownDisabled"
      ref="collapsibleContent"
      class="overflow-hidden data-[state=closed]:animate-collapsible-close data-[state=open]:animate-collapsible-open"
    >
      <div ref="collapsibleContentElement">
        <slot name="content" />
      </div>
    </CollapsibleContent>
  </CollapsibleRoot>
</template>

<script setup lang="ts">
import { CollapsibleContent, CollapsibleRoot } from 'radix-vue'
import { useResizeObserver } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    selected: boolean
    dropdownDisabled?: boolean
  }>(),
  {
    selected: false,
    dropdownDisabled: false,
  }
)

const isSelected = computed<boolean>(() => props.selected)

const collapsibleContent = useTemplateRef('collapsibleContent')

const collapsibleContentElement = useTemplateRef('collapsibleContentElement')

useResizeObserver(collapsibleContentElement, (entries) => {
  if (collapsibleContent.value) {
    const entry = entries[0]
    const { height } = entry.contentRect

    const currentCollapsibleHeight: number = parseInt(collapsibleContent.value.$el.style.getPropertyValue('--radix-collapsible-content-height'), 0)

    if (!isAnimating.value && currentCollapsibleHeight !== height) {
      collapsibleContent.value.$el.style.setProperty('--radix-collapsible-content-height', height)
    }
  }
})

const isAnimating = ref<boolean>(false)

watch([isSelected], () => {
  isAnimating.value = true
})

const onAnimationEnd = () => {
  isAnimating.value = false
}

onMounted(() => {
  if (collapsibleContent.value) {
    collapsibleContent.value.$el.addEventListener('animationend', onAnimationEnd)
  }
})

onUnmounted(() => {
  if (collapsibleContent.value) {
    collapsibleContent.value.$el.removeEventListener('animationend', onAnimationEnd)
  }
})
</script>
