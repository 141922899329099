import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Viimati vaadatud tooted

  <span style="color:#85c1dc;">**FI:**</span>
  Viimeksi katsotut tuotteet

  <span style="color:#85c1dc;">**RU:**</span>
  Недавно просмотренные товары
*/
/* @__NO_SIDE_EFFECTS__ */
const m1295 = msg(
  mf`Viimati vaadatud tooted`,
  mf`Viimeksi katsotut tuotteet`,
  mf`Недавно просмотренные товары`,
)

export { m1295 as 'w.search.recent-products' }