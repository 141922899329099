import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sooduskood

  <span style="color:#85c1dc;">**FI:**</span>
  Alennuskoodi

  <span style="color:#85c1dc;">**RU:**</span>
  Код скидки
*/
/* @__NO_SIDE_EFFECTS__ */
const m254 = msg(
  mf`Sooduskood`,
  mf`Alennuskoodi`,
  mf`Код скидки`,
)

export { m254 as 'w.ostukorv.soodustus.promo_code' }