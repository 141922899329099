import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kasutaja

  <span style="color:#85c1dc;">**FI:**</span>
  Käyttäjä

  <span style="color:#85c1dc;">**RU:**</span>
  Пользователь
*/
/* @__NO_SIDE_EFFECTS__ */
const m5 = msg(
  mf`Kasutaja`,
  mf`Käyttäjä`,
  mf`Пользователь`,
)

export { m5 as 'w.header.menu.account-menu-name' }