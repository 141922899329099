/*
  Any pagination can be generalized to a form such as (c = current, t = total):

  1 2 ... (c-2) (c-1) c (c+1) (c+2) ... (t-1) t

  At the moment only siblings of size 1 and 2 are considered, resulting in incredibly verbose code.

  There may be room to improvement through a better generalization when larger values for siblings are desired.
*/

enum PaginationElementKind {
  LEFT_EDGE,
  LEFT_ELLIPSIS_SINGLE,
  LEFT_ELLIPSIS,
  CURRENT_MINUS_TWO,
  CURRENT_MINUS_ONE,
  CURRENT,
  CURRENT_PLUS_ONE,
  CURRENT_PLUS_TWO,
  RIGHT_ELLIPSIS,
  RIGHT_ELLIPSIS_SINGLE,
  RIGHT_EDGE,
}

interface PaginationBase {
  breakpoints: number[]
  classes?: string
}

interface PaginationButton extends PaginationBase {
  type: 'button'
  number: number
}

interface PaginationEllipsis extends PaginationBase {
  type: 'ellipsis'
}

type PaginationElement = PaginationButton | PaginationEllipsis

const STEPS = [5, 7, 9] as const // page numbers seen at default, xs, sm breakpoints
const VISIBLE_CLASSES = [null, 'xs:list-item', 'sm:list-item'] as const
const HIDDEN_CLASSES = ['hidden', 'xs:hidden', 'sm:hidden'] as const

export const pageNumbers = (currentPage: number, totalPages: number) => {
  const variants = [generateVariant(currentPage, totalPages, 2, true), generateVariant(currentPage, totalPages, 1, true), generateVariant(currentPage, totalPages, 1, false)]

  const elements: Record<PaginationElementKind, PaginationElement> = {
    [PaginationElementKind.LEFT_EDGE]: { type: 'button', number: 1, breakpoints: [] },
    [PaginationElementKind.LEFT_ELLIPSIS_SINGLE]: { type: 'button', number: 2, breakpoints: [] },
    [PaginationElementKind.LEFT_ELLIPSIS]: { type: 'ellipsis', breakpoints: [] },
    [PaginationElementKind.CURRENT_MINUS_TWO]: { type: 'button', number: currentPage - 2, breakpoints: [] },
    [PaginationElementKind.CURRENT_MINUS_ONE]: { type: 'button', number: currentPage - 1, breakpoints: [] },
    [PaginationElementKind.CURRENT]: { type: 'button', number: currentPage, breakpoints: [] },
    [PaginationElementKind.CURRENT_PLUS_ONE]: { type: 'button', number: currentPage + 1, breakpoints: [] },
    [PaginationElementKind.CURRENT_PLUS_TWO]: { type: 'button', number: currentPage + 2, breakpoints: [] },
    [PaginationElementKind.RIGHT_ELLIPSIS]: { type: 'ellipsis', breakpoints: [] },
    [PaginationElementKind.RIGHT_ELLIPSIS_SINGLE]: { type: 'button', number: totalPages - 1, breakpoints: [] },
    [PaginationElementKind.RIGHT_EDGE]: { type: 'button', number: totalPages, breakpoints: [] },
  }

  // Find widest variant that fits into current breakpoint step and mark the elements to be part of that breakpoint
  for (const step of STEPS) {
    for (const variant of variants) {
      if (variant.length <= step) {
        for (const element of variant) {
          elements[element].breakpoints.push(step)
        }
        break
      }
    }
  }

  return Object.values(elements)
    .filter((element) => element.breakpoints.length > 0)
    .map((element) => {
      const classes = getBreakpointClasses(element)
      if (classes.length) {
        element.classes = classes.join(' ')
      }

      return element
    })
}

// Converts steps at which a pagination element is visible to sequences of classes
const getBreakpointClasses = (element: PaginationElement) => {
  const classes: string[] = []

  for (let i = 0; i < STEPS.length; i++) {
    const current = STEPS[i]
    const previous = STEPS[i - 1] || -1
    const hiddenClass = HIDDEN_CLASSES[i]
    const visibleClass = VISIBLE_CLASSES[i]

    // Change from visible to hidden
    if (hiddenClass && (previous === -1 || element.breakpoints.includes(previous)) && !element.breakpoints.includes(current)) {
      classes.push(hiddenClass)
    }

    // Change from hidden to visible
    if (visibleClass && (previous === -1 || !element.breakpoints.includes(previous)) && element.breakpoints.includes(current)) {
      classes.push(visibleClass)
    }
  }

  return classes
}

// Generates an array of named tokens that are visible for a specific configuration.
const generateVariant = (current: number, total: number, siblings: 1 | 2, edges: boolean) => {
  const windowMin = Math.max(current - siblings, 1)
  const windowMax = Math.min(current + siblings, total)

  const elements: PaginationElementKind[] = []

  if (windowMin > 1) {
    if (edges || windowMin === 1 + 1) {
      elements.push(PaginationElementKind.LEFT_EDGE)
    }

    if (edges && windowMin === 1 + 2) {
      elements.push(PaginationElementKind.LEFT_ELLIPSIS_SINGLE)
    } else if (windowMin >= 1 + 2) {
      elements.push(PaginationElementKind.LEFT_ELLIPSIS)
    }
  }

  if (windowMin < current - 1) {
    elements.push(PaginationElementKind.CURRENT_MINUS_TWO)
  }

  if (windowMin < current) {
    elements.push(PaginationElementKind.CURRENT_MINUS_ONE)
  }

  elements.push(PaginationElementKind.CURRENT)

  if (current < windowMax) {
    elements.push(PaginationElementKind.CURRENT_PLUS_ONE)
  }

  if (current + 1 < windowMax) {
    elements.push(PaginationElementKind.CURRENT_PLUS_TWO)
  }

  if (windowMax < total) {
    if (edges || windowMax === total - 1) {
      elements.push(PaginationElementKind.RIGHT_EDGE)
    }

    if (edges && windowMax === total - 2) {
      elements.push(PaginationElementKind.RIGHT_ELLIPSIS_SINGLE)
    } else if (windowMax <= total - 2) {
      elements.push(PaginationElementKind.RIGHT_ELLIPSIS)
    }
  }

  return elements
}
