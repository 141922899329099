<template>
  <div>
    <component :is="props.url ? NuxtLink : 'div'" :to="props.url" class="flex items-center p-4" :class="[{ 'transition-colors': props.url }, bgClass]">
      <Icon :name="iconName" class="mr-3 size-6" :class="iconClass" />
      {{ props.text }}
    </component>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'
import Icon from '~/components/icon/Icon.vue'

const props = withDefaults(
  defineProps<{
    text?: string | null
    url?: string | null
    state?: keyof typeof stateIconMap
  }>(),
  {
    text: null,
    url: null,
    state: 'default',
  }
)

const stateIconMap = {
  informational: { name: 'info', class: 'text-information-bright' },
  attention: { name: 'close', class: 'text-attention-bright' },
  warning: { name: 'alarm', class: 'text-warning-bright' },
  light: { name: 'bulb', class: 'text-icon-active' },
  default: { name: 'bulb', class: 'text-icon-active' },
} as const

const iconName = computed(() => stateIconMap[props.state]?.name || stateIconMap.default.name)
const iconClass = computed(() => stateIconMap[props.state]?.class || stateIconMap.default.class)

const bgClass = computed(() => {
  if (props.url) {
    switch (props.state) {
      case 'informational':
        return 'bg-information-extra-light hover:bg-information-light'
      case 'attention':
        return 'bg-attention-extra-light hover:bg-attention-light'
      case 'warning':
        return 'bg-warning-extra-light hover:bg-warning-light'
      case 'light':
        return 'bg-background hover:bg-background-light'
      default:
        return 'bg-background-light-strong hover:bg-background-medium-strong'
    }
  } else {
    switch (props.state) {
      case 'informational':
        return 'bg-information-extra-light'
      case 'attention':
        return 'bg-attention-extra-light'
      case 'warning':
        return 'bg-warning-extra-light'
      case 'light':
        return 'bg-background'
      default:
        return 'bg-background-light-strong'
    }
  }
})
</script>
