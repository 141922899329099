import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kasutame küpsiseid teie sirvimiskogemuse parandamiseks, isikupärastatud reklaamide või sisu esitamiseks ja liikluse analüüsimiseks. Klõpsates "Luba küpsised", nõustute meie küpsiste kasutamisega.

  <span style="color:#85c1dc;">**FI:**</span>
  Käytämme evästeitä parantaaksemme selauskokemustasi, toimittaaksemme räätälöityjä mainoksia tai sisältöä ja analysoidaksemme liikennettä. Napsauttamalla "Salli evästeet" hyväksyt evästeiden käytön.

  <span style="color:#85c1dc;">**RU:**</span>
  Мы используем файлы cookie, чтобы улучшить ваш опыт просмотра, предоставлять персонализированную рекламу или контент и анализировать трафик. Нажимая „Разрешить файлы cookie“, Вы соглашаетесь на использование нами файлов cookie.
*/
/* @__NO_SIDE_EFFECTS__ */
const m88 = msg(
  mf`Kasutame küpsiseid teie sirvimiskogemuse parandamiseks, isikupärastatud reklaamide või sisu esitamiseks ja liikluse analüüsimiseks. Klõpsates "Luba küpsised", nõustute meie küpsiste kasutamisega.`,
  mf`Käytämme evästeitä parantaaksemme selauskokemustasi, toimittaaksemme räätälöityjä mainoksia tai sisältöä ja analysoidaksemme liikennettä. Napsauttamalla "Salli evästeet" hyväksyt evästeiden käytön.`,
  mf`Мы используем файлы cookie, чтобы улучшить ваш опыт просмотра, предоставлять персонализированную рекламу или контент и анализировать трафик. Нажимая „Разрешить файлы cookie“, Вы соглашаетесь на использование нами файлов cookie.`,
)

export { m88 as 'w.cookies.customize.text' }