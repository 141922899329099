import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  ON24.ee Sisustuskaubamaja

  <span style="color:#85c1dc;">**FI:**</span>
  ON24.fi Sisustustavaratalo

  <span style="color:#85c1dc;">**RU:**</span>
  ON24.ee Мебель и интерьер
*/
/* @__NO_SIDE_EFFECTS__ */
const m1709 = msg(
  mf`ON24.ee Sisustuskaubamaja`,
  mf`ON24.fi Sisustustavaratalo`,
  mf`ON24.ee Мебель и интерьер`,
)

export { m1709 as 'meta.title.add' }