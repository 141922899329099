import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Aitäh, et meiega liitusid!

  <span style="color:#85c1dc;">**FI:**</span>
  Kiitos, että liityit!

  <span style="color:#85c1dc;">**RU:**</span>
  Спасибо, что присоединились к нам!
*/
/* @__NO_SIDE_EFFECTS__ */
const m1119 = msg(
  mf`Aitäh, et meiega liitusid!`,
  mf`Kiitos, että liityit!`,
  mf`Спасибо, что присоединились к нам!`,
)

export { m1119 as 'w.register.title_alt' }