import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  tingimustega

  <span style="color:#85c1dc;">**FI:**</span>
  Avarda Tilin sääntöihin

  <span style="color:#85c1dc;">**RU:**</span>
  с условиями
*/
/* @__NO_SIDE_EFFECTS__ */
const m704 = msg(
  mf`tingimustega`,
  mf`Avarda Tilin sääntöihin`,
  mf`с условиями`,
)

export { m704 as 'w.basket.after_payment.agree_to_terms_link_word' }