import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Järelmaksu ankeedi täitmine

  <span style="color:#85c1dc;">**FI:**</span>
  Osittainen maksulomake

  <span style="color:#85c1dc;">**RU:**</span>
  Заполнение анкеты оплаты в рассрочку
*/
/* @__NO_SIDE_EFFECTS__ */
const m635 = msg(
  mf`Järelmaksu ankeedi täitmine`,
  mf`Osittainen maksulomake`,
  mf`Заполнение анкеты оплаты в рассрочку`,
)

export { m635 as 'w.basket.after_payment.title' }