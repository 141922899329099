import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Vajalikud küpsised aitavad parandada veebilehe kasutamismugavust võimaldades põhifunktsioone nagu lehtedel navigeerimine ja juurdepääsu veebilehe kaitstud aladele. Veebileht ei tööta ilma nende küpsisteta korralikult.

  <span style="color:#85c1dc;">**FI:**</span>
  Välttämättömät evästeet auttavat parantamaan verkkosivuston käytettävyyttä mahdollistamalla perustoiminnot, kuten sivulla navigoinnin ja pääsyn verkkosivuston suojatuille alueille. Sivusto ei toimi kunnolla ilman näitä evästeitä.

  <span style="color:#85c1dc;">**RU:**</span>
  Необходимые файлы cookie помогают улучшить удобство использования веб-сайта, обеспечивая выполнение основных функций, таких как навигация по страницам и доступ к защищенным областям веб-сайта. Без этих файлов cookie веб-сайт не будет работать должным образом.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1751 = msg(
  mf`Vajalikud küpsised aitavad parandada veebilehe kasutamismugavust võimaldades põhifunktsioone nagu lehtedel navigeerimine ja juurdepääsu veebilehe kaitstud aladele. Veebileht ei tööta ilma nende küpsisteta korralikult.`,
  mf`Välttämättömät evästeet auttavat parantamaan verkkosivuston käytettävyyttä mahdollistamalla perustoiminnot, kuten sivulla navigoinnin ja pääsyn verkkosivuston suojatuille alueille. Sivusto ei toimi kunnolla ilman näitä evästeitä.`,
  mf`Необходимые файлы cookie помогают улучшить удобство использования веб-сайта, обеспечивая выполнение основных функций, таких как навигация по страницам и доступ к защищенным областям веб-сайта. Без этих файлов cookie веб-сайт не будет работать должным образом.`,
)

export { m1751 as 'w.cookies.customize.mandatory.text' }