import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sisesta kood

  <span style="color:#85c1dc;">**FI:**</span>
  Syötä koodi

  <span style="color:#85c1dc;">**RU:**</span>
  Введите код
*/
/* @__NO_SIDE_EFFECTS__ */
const m806 = msg(
  mf`Sisesta kood`,
  mf`Syötä koodi`,
  mf`Введите код`,
)

export { m806 as 'register.recommendation_code_enter' }