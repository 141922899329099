/// <reference types="facebook-js-sdk" />
const getLanguageCode = (languageId: number) => {
  switch (languageId) {
    case 1:
      return 'et_EE'
    case 2:
      return 'fi_FI'
    case 3:
      return 'ru_RU'
  }

  return 'en_US'
}

export const useFacebookSdk = () => {
  const {
    public: { languageId },
  } = useRuntimeConfig()

  let readyPromise: Promise<void> = Promise.resolve()

  return useScript(
    {
      src: `https://connect.facebook.net/${getLanguageCode(languageId)}/sdk.js`,
    },
    {
      beforeInit() {
        if (import.meta.client) {
          window.fbAsyncInit = function () {
            FB.init({
              appId: '490770987692073',
              xfbml: false,
              version: 'v21.0',
            })

            readyPromise = new Promise((resolve) => {
              resolve()
            })
          }
        }
      },
      use() {
        const wrapFn = <T extends keyof typeof window.FB>(fn: T) =>
          window.FB?.[fn] ||
          ((...args: Parameters<(typeof window.FB)[T]>) => {
            readyPromise.then(() => {
              return window.FB[fn](...args)
            })
          })

        return { login: wrapFn('login') }
      },
      trigger: 'manual',
    }
  )
}
