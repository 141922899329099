import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Toote lemmikutesse lisamiseks logi sisse.

  <span style="color:#85c1dc;">**FI:**</span>
  Kirjaudu sisään lisätäksesi suosikkejasi.

  <span style="color:#85c1dc;">**RU:**</span>
  Войдите, чтобы добавить товар в избранное.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1679 = msg(
  mf`Toote lemmikutesse lisamiseks logi sisse.`,
  mf`Kirjaudu sisään lisätäksesi suosikkejasi.`,
  mf`Войдите, чтобы добавить товар в избранное.`,
)

export { m1679 as 'w.login.wishlist_welcome' }