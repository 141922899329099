import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  https://www.e-kaubanduseliit.ee/liidust/usaldusmargis

  <span style="color:#85c1dc;">**FI:**</span>
  

  <span style="color:#85c1dc;">**RU:**</span>
  https://www.e-kaubanduseliit.ee/ru
*/
/* @__NO_SIDE_EFFECTS__ */
const m1368 = msg(
  mf`https://www.e-kaubanduseliit.ee/liidust/usaldusmargis`,
  mf``,
  mf`https://www.e-kaubanduseliit.ee/ru`,
)

export { m1368 as 'link.trusted_store.href' }