<template>
  <FloatingLabelInputWrapper :label="label" :name="name" :required="required" :is-error="props.isError">
    <FloatingLabelTextareaInput
      v-model="modelValue"
      :name="name"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :is-error="isError"
      :autocomplete="autocomplete"
      :validation="validation"
      :rows="rows"
      :cols="cols"
    />
  </FloatingLabelInputWrapper>
</template>

<script setup lang="ts">
import FloatingLabelInputWrapper from './FloatingLabelInputWrapper.vue'
import FloatingLabelTextareaInput from './FloatingLabelTextareaInput.vue'

const modelValue = defineModel<string>()

const props = withDefaults(
  defineProps<{
    name: string
    label?: string
    placeholder?: string
    required?: boolean
    isError?: boolean
    autocomplete?: string
    validation?: string
    rows?: string
    cols?: string
  }>(),
  {
    required: false,
    isError: false,
    label: undefined,
    placeholder: '',
    autocomplete: undefined,
    validation: undefined,
    rows: undefined,
    cols: undefined,
  }
)
</script>
