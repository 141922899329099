import type { FacebookLoginErrorResponse, FacebookLoginResponse } from '~/types/on24-klient'
import type { FetchError } from 'ofetch'
import * as t from '~/messages'

export const useFacebookLogin = () => {
  const errorStore = useFacebookLoginErrorStore()

  const { load, onLoaded } = useFacebookSdk()

  const login = () => {
    load()

    onLoaded((proxy) => {
      proxy.login(
        (resp) => {
          ;(async (response) => {
            errorStore.clearErrors()

            if (response.status === 'connected' && response.authResponse.grantedScopes) {
              const scopesList = response.authResponse.grantedScopes.split(',')

              if (scopesList.includes('email') && scopesList.includes('public_profile')) {
                try {
                  const registerResponse = await $fetch<FacebookLoginResponse>('/api/login/facebook', {
                    method: 'POST',
                    body: {
                      accessToken: response.authResponse.accessToken,
                    },
                  })

                  await navigateTo(registerResponse.forwardTo, { external: true })
                  return
                } catch (error) {
                  const loginResponseData = error as FetchError<FacebookLoginErrorResponse>

                  if (loginResponseData.data) {
                    if (loginResponseData.data.type === 'LOGIN_ERROR') {
                      errorStore.setLoginErrorMessage(t['w.login.fb.error.generic_login']())
                      await navigateTo('/login')
                      return
                    } else if (loginResponseData.data.type === 'REGISTER_ERROR_MISSING_EMAIL') {
                      errorStore.setRegisterErrorMessage(t['w.login.fb.error.missing_email']())
                      await navigateTo('/register')
                      return
                    }
                  }
                }
              }
            }

            errorStore.setLoginErrorMessage(t['w.login.fb.error.generic_login']())
            await navigateTo('/login')
          })(resp)
        },
        {
          scope: 'public_profile,email',
          return_scopes: true,
          auth_type: 'rerequest',
        }
      )
    })
  }

  return { login }
}
