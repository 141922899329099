import { useInnerHeight } from '~/composables/inner-height'
import { useThrottleFrame } from '~/composables/throttle-frame'

export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const windowInnerHeight = useInnerHeight()

    const updateWindowInnerHeight = () => {
      windowInnerHeight.value = window.innerHeight + 'px'
      document.documentElement.style.setProperty('--window-height', windowInnerHeight.value)
    }

    window.addEventListener('resize', useThrottleFrame(updateWindowInnerHeight))
    setTimeout(() => {
      updateWindowInnerHeight()
    }, 1000)
  },
})
