import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Venipak

  <span style="color:#85c1dc;">**FI:**</span>
  

  <span style="color:#85c1dc;">**RU:**</span>
  Venipak
*/
/* @__NO_SIDE_EFFECTS__ */
const m211 = msg(
  mf`Venipak`,
  mf``,
  mf`Venipak`,
)

export { m211 as 'link.venipak.alt' }