import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Logi sisse / Liitu

  <span style="color:#85c1dc;">**FI:**</span>
  Kirjaudu sisään

  <span style="color:#85c1dc;">**RU:**</span>
  Войти / Присоединиться
*/
/* @__NO_SIDE_EFFECTS__ */
const m1004 = msg(
  mf`Logi sisse / Liitu`,
  mf`Kirjaudu sisään`,
  mf`Войти / Присоединиться`,
)

export { m1004 as 'w.header.menu.login' }