import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kasutame vajalikke küpsiseid, mis võimaldavad meie veebisaidil korralikult töötada. Seadistame valikulised küpsised, et pakkuda teile meie veebisaidi sirvimisel kohandatud kujunduskogemust ja kuvada teie asukohale vastavat teavet. Samuti jagame teavet meie saidi kasutamise kohta oma sotsiaalmeedia-, reklaami- ja analüüsipartneritega. Klõpsates "Luba küpsised", nõustute meie küpsiste kasutamisega. Kui klõpsate valikul "Kohanda valikud", saate küpsised eraldi valida ja nende kohta lisateavet vaadata.

  <span style="color:#85c1dc;">**FI:**</span>
  Käytämme välttämättömiä evästeitä, jotka mahdollistavat verkkosivustomme asianmukaisen toiminnan. Asetamme valinnaiset evästeet tarjotaksemme sinulle räätälöidyn suunnittelukokemuksen selatessasi verkkosivustoamme ja näyttääksemme sijaintiasi koskevia tietoja. Jaamme myös tietoja sivustomme käytöstäsi sosiaalisen median, mainos- ja analytiikkakumppaneidemme kanssa. Napsauttamalla "Salli evästeet" hyväksyt evästeiden käytön. Jos napsautat "Muokkaa vaihtoehtoja", voit valita evästeet yksitellen ja nähdä niistä lisätietoja.

  <span style="color:#85c1dc;">**RU:**</span>
  Мы используем необходимые файлы cookie, которые обеспечивают работу нашего веб-сайта. Мы устанавливаем выборочные файлы cookie, чтобы предоставить Вам индивидуальный дизайн при просмотре нашего веб-сайта и отображать информацию, соответствующую вашему местоположению. Мы также делимся информацией об использовании Вами нашего сайта с нашими партнерами в социальных сетях, маркетинге и аналитике. Нажимая „Разрешить файлы cookie“, Вы соглашаетесь на использование нами файлов cookie. Если Вы нажмете „Настроить параметры“, вы сможете выбрать файлы cookie по отдельности и просмотреть дополнительную информацию о них.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1095 = msg(
  mf`Kasutame vajalikke küpsiseid, mis võimaldavad meie veebisaidil korralikult töötada. Seadistame valikulised küpsised, et pakkuda teile meie veebisaidi sirvimisel kohandatud kujunduskogemust ja kuvada teie asukohale vastavat teavet. Samuti jagame teavet meie saidi kasutamise kohta oma sotsiaalmeedia-, reklaami- ja analüüsipartneritega. Klõpsates "Luba küpsised", nõustute meie küpsiste kasutamisega. Kui klõpsate valikul "Kohanda valikud", saate küpsised eraldi valida ja nende kohta lisateavet vaadata.`,
  mf`Käytämme välttämättömiä evästeitä, jotka mahdollistavat verkkosivustomme asianmukaisen toiminnan. Asetamme valinnaiset evästeet tarjotaksemme sinulle räätälöidyn suunnittelukokemuksen selatessasi verkkosivustoamme ja näyttääksemme sijaintiasi koskevia tietoja. Jaamme myös tietoja sivustomme käytöstäsi sosiaalisen median, mainos- ja analytiikkakumppaneidemme kanssa. Napsauttamalla "Salli evästeet" hyväksyt evästeiden käytön. Jos napsautat "Muokkaa vaihtoehtoja", voit valita evästeet yksitellen ja nähdä niistä lisätietoja.`,
  mf`Мы используем необходимые файлы cookie, которые обеспечивают работу нашего веб-сайта. Мы устанавливаем выборочные файлы cookie, чтобы предоставить Вам индивидуальный дизайн при просмотре нашего веб-сайта и отображать информацию, соответствующую вашему местоположению. Мы также делимся информацией об использовании Вами нашего сайта с нашими партнерами в социальных сетях, маркетинге и аналитике. Нажимая „Разрешить файлы cookie“, Вы соглашаетесь на использование нами файлов cookie. Если Вы нажмете „Настроить параметры“, вы сможете выбрать файлы cookie по отдельности и просмотреть дополнительную информацию о них.`,
)

export { m1095 as 'w.cookies.preview.text' }