import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sinu Facebooki kontol puudub konto loomiseks vajalik kinnitatud e-posti aadress. Kinnita oma e-posti aadress Facebookis või kasuta registreerimiseks allolevat vormi.

  <span style="color:#85c1dc;">**FI:**</span>
  Facebook-tililtäsi puuttuu vahvistettu sähköpostiosoite, joka tarvitaan tilin luomiseen. Vahvista sähköpostiosoitteesi Facebookissa tai käytä alla olevaa lomaketta rekisteröitymiseen.

  <span style="color:#85c1dc;">**RU:**</span>
  На вашем аккаунте Facebook отсутствует подтверждённый адрес электронной почты, необходимый для создания учётной записи. Подтвердите свой адрес электронной почты в Facebook или используйте форму ниже для регистрации.
*/
/* @__NO_SIDE_EFFECTS__ */
const m918 = msg(
  mf`Sinu Facebooki kontol puudub konto loomiseks vajalik kinnitatud e-posti aadress. Kinnita oma e-posti aadress Facebookis või kasuta registreerimiseks allolevat vormi.`,
  mf`Facebook-tililtäsi puuttuu vahvistettu sähköpostiosoite, joka tarvitaan tilin luomiseen. Vahvista sähköpostiosoitteesi Facebookissa tai käytä alla olevaa lomaketta rekisteröitymiseen.`,
  mf`На вашем аккаунте Facebook отсутствует подтверждённый адрес электронной почты, необходимый для создания учётной записи. Подтвердите свой адрес электронной почты в Facebook или используйте форму ниже для регистрации.`,
)

export { m918 as 'w.login.fb.error.missing_email' }