import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sinu ON24 raha

  <span style="color:#85c1dc;">**FI:**</span>
  Sinun ON24-rahasi

  <span style="color:#85c1dc;">**RU:**</span>
  Ваши деньги ON24
*/
/* @__NO_SIDE_EFFECTS__ */
const m1069 = msg(
  mf`Sinu ON24 raha`,
  mf`Sinun ON24-rahasi`,
  mf`Ваши деньги ON24`,
)

export { m1069 as 'w.account.menu.account_balance' }