import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kategooriad

  <span style="color:#85c1dc;">**FI:**</span>
  Kategoriat

  <span style="color:#85c1dc;">**RU:**</span>
  Категории
*/
/* @__NO_SIDE_EFFECTS__ */
const m1405 = msg(
  mf`Kategooriad`,
  mf`Kategoriat`,
  mf`Категории`,
)

export { m1405 as 'w.search.categories' }