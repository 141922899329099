import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Otsi

  <span style="color:#85c1dc;">**FI:**</span>
  Hae

  <span style="color:#85c1dc;">**RU:**</span>
  Найти
*/
/* @__NO_SIDE_EFFECTS__ */
const m884 = msg(
  mf`Otsi`,
  mf`Hae`,
  mf`Найти`,
)

export { m884 as 'search.action' }