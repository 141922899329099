/// <reference types="grecaptcha" />
declare global {
  interface Window {
    recaptchaOnLoadCallback: () => void
  }
}

export const useRecaptcha = sharedComposable(() => {
  let readyPromise: Promise<void> = Promise.resolve()

  const { functionalCookieConsentScriptTrigger } = useCookieConsent()

  return useScript(
    {
      src: 'https://www.google.com/recaptcha/api.js?onload=recaptchaOnLoadCallback&render=explicit',
      crossorigin: false,
    },
    {
      beforeInit() {
        if (import.meta.client) {
          readyPromise = new Promise((resolve) => {
            window.recaptchaOnLoadCallback = resolve
          })
        }
      },
      use() {
        return {
          render: (...args: Parameters<typeof window.grecaptcha.render>) => readyPromise.then(() => window.grecaptcha.render(...args)),
          execute: (...args: Parameters<typeof window.grecaptcha.execute>) => readyPromise.then(() => window.grecaptcha.execute(...args)),
          reset: (...args: Parameters<typeof window.grecaptcha.reset>) => readyPromise.then(() => window.grecaptcha.reset(...args)),
          getResponse: (...args: Parameters<typeof window.grecaptcha.getResponse>) => readyPromise.then(() => window.grecaptcha.getResponse(...args)),
        }
      },
      trigger: functionalCookieConsentScriptTrigger,
    }
  )
})
