import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Palun täida ankeet oma andmetega ning teeme sulle parima võimaliku järelmaksu pakkumise.

  <span style="color:#85c1dc;">**FI:**</span>
  Täytä lomake osittaista maksua varten

  <span style="color:#85c1dc;">**RU:**</span>
  Пожалуйста, заполните анкету с Вашими данными и мы сделаем Вам лучшее предложение по рассрочке.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1131 = msg(
  mf`Palun täida ankeet oma andmetega ning teeme sulle parima võimaliku järelmaksu pakkumise.`,
  mf`Täytä lomake osittaista maksua varten`,
  mf`Пожалуйста, заполните анкету с Вашими данными и мы сделаем Вам лучшее предложение по рассрочке.`,
)

export { m1131 as 'w.basket.after_payment.description' }