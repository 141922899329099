import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Muuda

  <span style="color:#85c1dc;">**FI:**</span>
  Muuta

  <span style="color:#85c1dc;">**RU:**</span>
  Изменить
*/
/* @__NO_SIDE_EFFECTS__ */
const m423 = msg(
  mf`Muuda`,
  mf`Muuta`,
  mf`Изменить`,
)

export { m423 as 'w.basket.change_previous_step' }