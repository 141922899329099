import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Mööbel ja sisustus - ON24

  <span style="color:#85c1dc;">**FI:**</span>
  Sisustus ja huonekalut - ON24

  <span style="color:#85c1dc;">**RU:**</span>
  Мебель и убранство - ON24
*/
/* @__NO_SIDE_EFFECTS__ */
const m344 = msg(
  mf`Mööbel ja sisustus - ON24`,
  mf`Sisustus ja huonekalut - ON24`,
  mf`Мебель и убранство - ON24`,
)

export { m344 as 'p.header.logo.alt' }