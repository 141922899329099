import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sinu ON24 raha

  <span style="color:#85c1dc;">**FI:**</span>
  ON24 rahasi saldo

  <span style="color:#85c1dc;">**RU:**</span>
  Баланс на счёте клиента
*/
/* @__NO_SIDE_EFFECTS__ */
const m406 = msg(
  mf`Sinu ON24 raha`,
  mf`ON24 rahasi saldo`,
  mf`Баланс на счёте клиента`,
)

export { m406 as 'w.header.menu.balance' }