import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kliki ja registreeru siin

  <span style="color:#85c1dc;">**FI:**</span>
  Klikkaa ja rekisteröidy tästä

  <span style="color:#85c1dc;">**RU:**</span>
  Зарегистрируйся здесь
*/
/* @__NO_SIDE_EFFECTS__ */
const m1697 = msg(
  mf`Kliki ja registreeru siin`,
  mf`Klikkaa ja rekisteröidy tästä`,
  mf`Зарегистрируйся здесь`,
)

export { m1697 as 'w.login.register-account' }