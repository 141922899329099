<template>
  <div class="relative bg-background-light-strong">
    <div
      class="pb-6 pt-4"
      :class="{
        'h-container': props.isSeparateModule,
        'after:absolute after:-inset-x-full after:inset-y-0 after:-z-10 after:bg-background-light-strong': !props.isSeparateModule,
      }"
    >
      <h2 v-if="t['w.module.value_propositions.title']() && props.hasTitle" class="py-6 text-center sm:pt-4">{{ t['w.module.value_propositions.title']() }}</h2>
      <div class="grid grid-cols-2 justify-items-center" :class="methodListClass">
        <div v-for="(method, methodIndex) in data" :key="`method-${methodIndex}`" class="w-full max-w-[360px] px-4 py-6 text-center" :class="methodItemClass">
          <Icon v-if="method.iconName" :name="method.iconName" class="size-[52px] text-icon-active" />
          <p class="my-2 text-subtitle font-medium">{{ method.title }}</p>
          <p class="text-base text-text-medium">{{ method.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from '~/components/icon/Icon.vue'
import * as t from '~/messages'

interface ValueProposition {
  title: string
  description: string
  iconName: string
}

const props = withDefaults(
  defineProps<{
    data?: ValueProposition[] | null
    hasTitle?: boolean
    isSeparateModule?: boolean
  }>(),
  {
    data: null,
    hasTitle: true,
    isSeparateModule: true,
  }
)

const elementCount = computed(() => props.data?.length ?? 0)

const methodListClass = computed(() => {
  switch (elementCount.value) {
    case 2:
      return 'md:grid-cols-2 md:gap-5'
    case 3:
      return 'md:grid-cols-3 md:gap-5'
    case 5:
      return 'md:grid-cols-3 lg:grid-cols-5 md:gap-5'
    case 6:
      return 'md:grid-cols-3 md:gap-5'
    default:
      return 'md:flex md:flex-wrap md:justify-around'
  }
})

const methodItemClass = computed(() => {
  if (![2, 3, 5, 6].includes(elementCount.value)) {
    return 'md:w-1/4'
  }

  return undefined
})
</script>
