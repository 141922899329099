import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  /info/maksmine

  <span style="color:#85c1dc;">**FI:**</span>
  /info/maksaminen

  <span style="color:#85c1dc;">**RU:**</span>
  /info/оплата
*/
/* @__NO_SIDE_EFFECTS__ */
const m22 = msg(
  mf`/info/maksmine`,
  mf`/info/maksaminen`,
  mf`/info/оплата`,
)

export { m22 as 'link.payment.href' }