import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Lisa

  <span style="color:#85c1dc;">**FI:**</span>
  Lisää

  <span style="color:#85c1dc;">**RU:**</span>
  Добавить
*/
/* @__NO_SIDE_EFFECTS__ */
const m667 = msg(
  mf`Lisa`,
  mf`Lisää`,
  mf`Добавить`,
)

export { m667 as 'w.global.product.favourite' }