import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  /info/privaatsus-ja-andmekaitse\#cookies

  <span style="color:#85c1dc;">**FI:**</span>
  /info/tietosuojaseloste\#cookies

  <span style="color:#85c1dc;">**RU:**</span>
  /info/приватность\_и\_защита\_данных\#cookies
*/
/* @__NO_SIDE_EFFECTS__ */
const m390 = msg(
  mf`/info/privaatsus-ja-andmekaitse#cookies`,
  mf`/info/tietosuojaseloste#cookies`,
  mf`/info/приватность_и_защита_данных#cookies`,
)

export { m390 as 'link.cookies.href' }