import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Statistikaga seotud küpsised aitavad veebilehtede omanikel mõista, kuidas külastajad veebilehega suhtlevad kogudes ja avaldades andmeid anonüümselt.

  <span style="color:#85c1dc;">**FI:**</span>
  Tilastoihin liittyvät evästeet auttavat verkkosivustojen omistajia ymmärtämään, kuinka vierailijat ovat vuorovaikutuksessa verkkosivuston kanssa keräämällä ja julkaisemalla tietoja nimettömästi.

  <span style="color:#85c1dc;">**RU:**</span>
  Файлы cookie, связанные со статистикой, помогают владельцам веб-сайтов понять, как посетители взаимодействуют с веб-сайтом, анонимно собирая и публикуя данные.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1321 = msg(
  mf`Statistikaga seotud küpsised aitavad veebilehtede omanikel mõista, kuidas külastajad veebilehega suhtlevad kogudes ja avaldades andmeid anonüümselt.`,
  mf`Tilastoihin liittyvät evästeet auttavat verkkosivustojen omistajia ymmärtämään, kuinka vierailijat ovat vuorovaikutuksessa verkkosivuston kanssa keräämällä ja julkaisemalla tietoja nimettömästi.`,
  mf`Файлы cookie, связанные со статистикой, помогают владельцам веб-сайтов понять, как посетители взаимодействуют с веб-сайтом, анонимно собирая и публикуя данные.`,
)

export { m1321 as 'w.cookies.customize.analytical.text' }