import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Ostukorv

  <span style="color:#85c1dc;">**FI:**</span>
  Ostoskori

  <span style="color:#85c1dc;">**RU:**</span>
  Корзина
*/
/* @__NO_SIDE_EFFECTS__ */
const m1159 = msg(
  mf`Ostukorv`,
  mf`Ostoskori`,
  mf`Корзина`,
)

export { m1159 as 'w.account.menu.basket' }