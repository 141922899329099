import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sulge menüü

  <span style="color:#85c1dc;">**FI:**</span>
  Sulje valikko

  <span style="color:#85c1dc;">**RU:**</span>
  undefined
*/
/* @__NO_SIDE_EFFECTS__ */
const m337 = msg(
  mf`Sulge menüü`,
  mf`Sulje valikko`,
  mf`undefined`,
)

export { m337 as 'w.header.menu.close-menu' }