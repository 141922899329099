import * as t from '~/messages'

export const useAccountMenu = () => {
  const historyAttention = useOrderHistoryAttentionState()
  const balanceAttention = useAccountBalanceAttentionState()

  const resetHistoryAttention = () => {
    historyAttention.value = false
  }

  const resetBalanceAttention = () => {
    balanceAttention.value = false
  }

  const accountMenu = computed(() => [
    {
      name: t['w.account.menu.main'](),
      url: '/account/main',
    },
    {
      name: t['w.account.menu.order_history'](),
      url: '/account/order-history',
      attention: historyAttention.value,
    },
    {
      name: t['w.account.menu.account_balance'](),
      url: '/account/account-balance',
      attention: balanceAttention.value,
    },
    {
      name: t['w.account.menu.wishlist'](),
      url: '/account/wishlist',
    },
    {
      name: t['w.account.menu.last'](),
      url: '/prods/last',
    },
    {
      name: t['w.account.menu.basket'](),
      url: '/order/basket',
    },
  ])

  return { accountMenu, resetHistoryAttention, resetBalanceAttention }
}
