import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Küpsised

  <span style="color:#85c1dc;">**FI:**</span>
  Evästeet

  <span style="color:#85c1dc;">**RU:**</span>
  Файлов cookie
*/
/* @__NO_SIDE_EFFECTS__ */
const m1901 = msg(
  mf`Küpsised`,
  mf`Evästeet`,
  mf`Файлов cookie`,
)

export { m1901 as 'w.cookies.customize.privacy-page.title' }