import type { WatchStopHandle } from 'vue'

const translationUrlState = () => useState<string | null>('translationUrl', () => null)

export const useTranslationUrl = () => readonly(translationUrlState())

export const provideTranslationUrl = (maybeRefOrGetter: MaybeRefOrGetter<string | null | undefined>) => {
  const translationUrl = translationUrlState()

  let watcher: WatchStopHandle

  onMounted(() => {
    watcher = watch(
      () => toValue(maybeRefOrGetter) ?? null,
      (newValue) => {
        translationUrl.value = newValue
      },
      { immediate: true }
    )
  })

  onBeforeUnmount(() => {
    watcher()
    translationUrl.value = null
  })
}
