import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Täitke see väli.

  <span style="color:#85c1dc;">**FI:**</span>
  Täytä tämä kenttä.

  <span style="color:#85c1dc;">**RU:**</span>
  Заполните это поле.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1416 = msg(
  mf`Täitke see väli.`,
  mf`Täytä tämä kenttä.`,
  mf`Заполните это поле.`,
)

export { m1416 as 'floating-input.required' }