import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Vaata kõiki...

  <span style="color:#85c1dc;">**FI:**</span>
  Katso kaikki...

  <span style="color:#85c1dc;">**RU:**</span>
  Увидеть все...
*/
/* @__NO_SIDE_EFFECTS__ */
const m827 = msg(
  mf`Vaata kõiki...`,
  mf`Katso kaikki...`,
  mf`Увидеть все...`,
)

export { m827 as 'w.header.halls.gotoall' }