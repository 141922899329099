import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kauba kättetoimetamine

  <span style="color:#85c1dc;">**FI:**</span>
  Tilauksen toimittaminen

  <span style="color:#85c1dc;">**RU:**</span>
  Доставка товара
*/
/* @__NO_SIDE_EFFECTS__ */
const m1529 = msg(
  mf`Kauba kättetoimetamine`,
  mf`Tilauksen toimittaminen`,
  mf`Доставка товара`,
)

export { m1529 as 'w.basket.transport_section' }