import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Otsi toodet...

  <span style="color:#85c1dc;">**FI:**</span>
  Etsi tuote...

  <span style="color:#85c1dc;">**RU:**</span>
  Ищи продукта...
*/
/* @__NO_SIDE_EFFECTS__ */
const m1434 = msg(
  mf`Otsi toodet...`,
  mf`Etsi tuote...`,
  mf`Ищи продукта...`,
)

export { m1434 as 'header.search.placeholder' }