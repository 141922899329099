<template>
  <div
    class="flex flex-col"
    :class="{
      top: (modelValue !== '' && modelValue !== undefined && modelValue !== null) || focused,
      focused,
    }"
  >
    <label :class="props.hideLabel && 'sr-only'" class="mb-2 pl-4 text-sm font-semibold" :for="name">{{ label }}<span v-if="required" class="text-text-attention"> *</span></label>
    <div class="relative">
      <input
        class="box-border w-full rounded-button border border-border-medium py-3 text-lg outline-none transition-colors placeholder:text-transparent hover:border-border-medium-hover focus:border-border-medium-hover active:border-border-medium-hover md:text-base"
        :class="{
          'px-3': isSmall,
          'px-4': !isSmall,
          'border-text-attention': props.isError,
        }"
        :type="type"
        :name="name"
        :value="modelValue"
        :size="size"
        :autocomplete="autocomplete"
        :required="required"
        :placeholder="placeholder"
        @input="input"
        @invalid="invalid"
        @focus="focused = true"
        @blur="focused = false"
      />
      <div
        v-show="modelValue === ''"
        class="pointer-events-none absolute inset-0 border border-transparent py-4 text-text-medium md:py-3"
        :class="{
          'px-3 text-base': isSmall,
          'px-4': !isSmall,
        }"
      >
        <div class="overflow-hidden whitespace-pre break-normal">
          {{ placeholder }}
          <span v-if="props.required && props.hideLabel" class="text-text-attention">*</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as t from '~/messages'

const props = withDefaults(
  defineProps<{
    type?: string
    name: string
    placeholder?: string
    label: string
    required?: boolean
    modelValue: any
    size?: string | undefined
    autocomplete?: string
    hideLabel?: boolean
    fieldSize?: 'small'
    isError?: boolean
  }>(),
  {
    type: 'text',
    placeholder: '',
    required: false,
    size: undefined,
    autocomplete: undefined,
    hideLabel: false,
    fieldSize: undefined,
    isError: false,
  }
)

const focused = ref(false)

const isSmall = computed(() => {
  return props.fieldSize === 'small'
})

const emit = defineEmits<{
  'update:modelValue': [newValue: string]
}>()

const input = useSafeEvent<HTMLInputElement>((event) => {
  emit('update:modelValue', event.target.value)
  event.target.setCustomValidity('')
})

const invalid = useSafeEvent<HTMLInputElement>((event) => {
  if (props.required && (!props.modelValue || !props.modelValue.length)) {
    event.target.setCustomValidity(t['floating-input.required']())
  }
})
</script>
