<template>
  <div v-if="languageId === 1"><NuxtLink title="RUS" :to="translationUrl">По-русски</NuxtLink></div>
  <div v-else-if="languageId === 3"><NuxtLink title="EST" :to="translationUrl">Eesti keeles</NuxtLink></div>
</template>

<script setup lang="ts">
import { resolveURL } from 'ufo'

const {
  public: { altSiteBase, languageId },
} = useRuntimeConfig()

const translationUrlState = useTranslationUrl()
const translationUrl = computed(() => resolveURL(altSiteBase, translationUrlState.value ?? '/'))
</script>
