<template>
  <div>
    <button v-if="!props.isDesktop" ref="openButton" class="flex p-2" :aria-label="t['w.header.menu.open-menu']()" @click="openHeader">
      <Icon class="size-6" name="menu" />
    </button>
    <FocusTrap v-model:active="localIsOpen" :initial-focus="() => closeButton || false" :fallback-focus="() => openButton || undefined">
      <Transition enter-active-class="animate-translate-l-1/1-in" leave-active-class="animate-translate-l-1/1-out">
        <div
          v-show="isOpen || props.isDesktop"
          class="flex"
          :class="{
            'fixed inset-0 z-50 flex flex-col bg-background': !props.isDesktop,
          }"
        >
          <div v-if="!props.isDesktop" class="flex items-center justify-between border-b border-border-medium px-5 py-4">
            <Languages class="rounded-3xl border border-border px-6 py-3" />
            <button ref="closeButton" class="-mr-2 p-2" @click="closeHeader">
              <span class="sr-only">{{ t['w.header.menu.close-menu']() }}</span>
              <Icon name="close" class="size-6" />
            </button>
          </div>
          <div
            :class="{
              'pointer-events-auto mx-auto w-auto': props.isDesktop,
              'relative grow': !props.isDesktop,
            }"
            @mousemove="mouseOnHeaderMenu(true)"
            @mouseleave="mouseOnHeaderMenu(false)"
          >
            <div
              class="text-sm"
              :class="{
                'absolute inset-0 flex flex-col': !props.isDesktop,
              }"
            >
              <template v-if="props.isDesktop">
                <span
                  v-for="(headerLink, index) of headerLinks"
                  :key="`header-link-${index}`"
                  class="inline-block px-2.5 py-3 text-center text-button-m font-medium transition-colors hover:!text-text-strong"
                  :class="props.menuItemClass"
                  :style="{ color: headerLink.textColor || undefined }"
                  @mouseover="mouseOnSpecificHeaderLink(index)"
                >
                  <span class="pointer-events-none leading-normal">{{ headerLink.name }}</span>
                </span>
                <div
                  v-for="(headerLink, index) of headerLinks"
                  v-show="isHeaderMenuOpen && lastUsedHeaderMenuIndex === index"
                  :key="`header-link-content-${index}`"
                  class="h-container absolute inset-x-0 z-30"
                  :class="props.menuContainerClass"
                >
                  <HeaderGroupLinkContent
                    class="overflow-y-auto rounded-b-2xl bg-white p-10 shadow-2xl"
                    :item="headerLink"
                    :style="{
                      maxHeight: `calc(100vh - 40px - ${headerHeight}px)`,
                    }"
                    @link-clicked="mouseOnHeaderMenu(false)"
                  />
                </div>
              </template>
              <BurgerMenuView v-else class="absolute inset-0 flex flex-col" is-root-menu :menu-name="t['w.header.menu.root-menu-name']()">
                <div v-show="activeMenu === ''">
                  <div class="border-b py-3">
                    <BurgerMenuLink
                      v-model:active-menu="activeMenu"
                      class="px-5 py-2.5"
                      :opens-menu="loggedIn"
                      :menu-name="t['w.header.menu.account-menu-name']()"
                      @click="onAccountButtonClick"
                    >
                      <div class="flex items-center">
                        <div class="mr-1 text-base font-semibold">{{ welcomeLabel }}</div>
                        <Icon name="account" class="size-6" />
                        <Icon v-if="loggedIn" class="ml-auto size-6" name="arrow-right" />
                      </div>
                    </BurgerMenuLink>
                    <NuxtLink class="flex items-center px-5 py-2.5" to="/order/basket">
                      <span class="text-base">{{ t['w.menu.ostukorv']() }}</span>
                      <span
                        v-if="basketItemCount > 0"
                        class="ml-1 box-content flex size-4 items-center justify-center rounded-full border-2 border-border-inverted bg-icon-active text-[0.75rem]/[1] text-border-inverted"
                      >
                        {{ basketItemCount }}
                      </span>
                    </NuxtLink>
                  </div>
                  <div class="py-3">
                    <BurgerMenuLink
                      v-for="(headerLink, index) of headerLinks"
                      :key="`header-link-${index}`"
                      v-model:active-menu="activeMenu"
                      class="px-5 py-3"
                      :url="headerLink.url"
                      :opens-menu="!!(headerLink.children && headerLink.children.length > 0)"
                      :menu-name="headerLink.name"
                    >
                      <div class="flex items-center justify-between text-button-xl font-medium">
                        {{ headerLink.name }}
                        <Icon v-if="headerLink.children && headerLink.children.length > 0" name="arrow-right" class="size-6" />
                      </div>
                    </BurgerMenuLink>
                  </div>

                  <AccordionRoot type="single" collapsible as-child class="border-t border-border-light p-5">
                    <ul>
                      <AccordionItem v-for="(item, index) in secondaryLinks" :key="`secondary-link-${index}`" as-child :value="`secondary-link-${index}`" class="mb-5 last:mb-0">
                        <li>
                          <AccordionHeader as-child class="group flex w-full items-center justify-between text-base text-text-strong data-[state=closed]:text-text">
                            <AccordionTrigger>
                              {{ item.name }}
                              <Icon class="size-6 text-text transition-transform ease-in-out group-data-[state=closed]:rotate-180" name="chevron-up" />
                            </AccordionTrigger>
                          </AccordionHeader>

                          <AccordionContent class="overflow-hidden data-[state=closed]:animate-accordion-close data-[state=open]:animate-accordion-open">
                            <ul class="mt-2 rounded-lg bg-background-light-strong px-6">
                              <li v-for="(footerLink, footerLinkIndex) in item.children" :key="`secondary-link-${footerLinkIndex}`" class="py-2 text-base first:pt-6 last:pb-6">
                                <NuxtLink v-if="footerLink.url" :to="footerLink.url" class="hover:underline">{{ footerLink.name }}</NuxtLink>
                                <template v-else>{{ footerLink.name }}</template>
                              </li>
                            </ul>
                          </AccordionContent>
                        </li>
                      </AccordionItem>
                    </ul>
                  </AccordionRoot>
                </div>
                <BurgerMenuView
                  v-if="loggedIn"
                  v-model:active-menu="activeMenu"
                  class="absolute inset-0 flex flex-col"
                  :menu-name="t['w.header.menu.account-menu-name']()"
                  :breadcrumbs="t['w.header.menu.root-menu-name']()"
                >
                  <AccountMenu :name-label="welcomeLabel" />
                </BurgerMenuView>
                <!-- TODO: remove hide-top-level-link when the old web becomes irrelevant and the link itself gets removed -->
                <BurgerMenuNavigationView
                  v-for="(headerLink, index) of headerLinks"
                  :key="`header-burger-navigation-view-${index}`"
                  v-model:active-menu="activeMenu"
                  class="absolute inset-0"
                  :menu-name="headerLink.name"
                  :item="headerLink"
                  :children="headerLink.children"
                  hide-top-level-link
                  :breadcrumbs="t['w.header.menu.root-menu-name']()"
                />
              </BurgerMenuView>
            </div>
          </div>
        </div>
      </Transition>
    </FocusTrap>
  </div>
</template>

<script setup lang="ts">
import { useBreakpoint } from '~/composables/breakpoint'

import HeaderGroupLinkContent from '~/components/header/HeaderGroupLinkContent.vue'
import AccountMenu from '~/components/header/AccountMenu.vue'
import BurgerMenuLink from '~/components/header/BurgerMenuLink.vue'
import BurgerMenuView from '~/components/header/BurgerMenuView.vue'
import BurgerMenuNavigationView from '~/components/header/BurgerMenuNavigationView.vue'
import Languages from '~/components/header/Languages.vue'
import Icon from '~/components/icon/Icon.vue'
import { FocusTrap } from 'focus-trap-vue'
import { useHeaderStore } from '~/stores/header'
import { useLoginModalStore } from '~/stores/login-modal'
import { storeToRefs } from 'pinia'
import type { HeaderLink } from '~/types/header-link'
import { AccordionRoot, AccordionItem, AccordionHeader, AccordionTrigger, AccordionContent } from 'radix-vue'
import { useBasketState, useUserState } from '~/composables/states'
import type { Link } from '~/types/link'
import * as t from '~/messages'

interface FooterSection {
  name: string
  children: Link[]
}

const { isBreakpoint } = useBreakpoint()

const props = withDefaults(
  defineProps<{
    isOpen?: boolean
    headerLinks: HeaderLink[]
    secondaryLinks?: FooterSection[] | null
    isDesktop?: boolean
    menuContainerClass?: string | Record<string, boolean>
    menuItemClass?: string | Record<string, boolean>
  }>(),
  {
    isOpen: false,
    secondaryLinks: null,
    isDesktop: false,
    menuContainerClass: '',
    menuItemClass: '',
  }
)

const loginModalStore = useLoginModalStore()

const basket = useBasketState()

const basketItemCount = basket.value

const user = useUserState()

const openButton = useTemplateRef('openButton')

const loggedIn = !!user.value
const userName = (user.value && user.value.fullName) || ''
const welcomeLabel = computed(() => {
  return user.value ? t['w.header.menu.greeting']() + ' ' + userName : t['w.header.menu.login']()
})

const closeButton = useTemplateRef('closeButton')

const { activeMenu, totalHeaderHeight: headerHeight } = storeToRefs(useHeaderStore())
const mouseIsOnHeaderMenuIndex = ref(-1)
const lastUsedHeaderMenuIndex = ref(-1)
const mouseIsOnHeaderMenu = ref(false)
const isHeaderMenuOpen = ref(false)
let openingTimeout: number | null = null

const emit = defineEmits<{
  'update:isOpen': [isOpen: boolean]
}>()

const localIsOpen = computed({
  get: () => {
    return props.isOpen
  },
  set: (value) => {
    emit('update:isOpen', value)
  },
})

const openHeader = () => {
  emit('update:isOpen', true)
  activeMenu.value = ''
}

const closeHeader = () => {
  isHeaderMenuOpen.value = false
  emit('update:isOpen', false)
}

const mouseOnSpecificHeaderLink = (index: number) => {
  mouseIsOnHeaderMenuIndex.value = index
  if (index >= 0) {
    lastUsedHeaderMenuIndex.value = index
  }
}

const mouseOnHeaderMenu = (toggle: boolean) => {
  mouseIsOnHeaderMenu.value = toggle
  if (toggle) {
    openingTimeout = window.setTimeout(() => {
      isHeaderMenuOpen.value = mouseIsOnHeaderMenu.value
    }, 500)
  } else {
    if (openingTimeout) {
      clearTimeout(openingTimeout)
      isHeaderMenuOpen.value = mouseIsOnHeaderMenu.value
    }

    mouseOnSpecificHeaderLink(-1)
  }
}

const onAccountButtonClick = () => {
  if (!loggedIn) {
    loginModalStore.open(t['w.login.welcome']())
  }
}

const route = useRoute()
watch(
  () => route.fullPath,
  () => closeHeader()
)

watch(isBreakpoint('md'), (newValue) => {
  if (newValue) {
    closeHeader()
  }
})
</script>
