import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sisesta oma e-posti aadress

  <span style="color:#85c1dc;">**FI:**</span>
  Kirjoita sähköpostiosoitteesi

  <span style="color:#85c1dc;">**RU:**</span>
  Введите адрес Вашей эл.почты
*/
/* @__NO_SIDE_EFFECTS__ */
const m237 = msg(
  mf`Sisesta oma e-posti aadress`,
  mf`Kirjoita sähköpostiosoitteesi`,
  mf`Введите адрес Вашей эл.почты`,
)

export { m237 as 'w.login.placeholder.email' }