import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Mööbel ja sisustus. Suurim valik soodsate hindadega

  <span style="color:#85c1dc;">**FI:**</span>
  Sisustus ja huonekalut netistä. Laajin valikoima kalusteita

  <span style="color:#85c1dc;">**RU:**</span>
  Мебель и интерьер. Наибольший выбор мебели
*/
/* @__NO_SIDE_EFFECTS__ */
const m1225 = msg(
  mf`Mööbel ja sisustus. Suurim valik soodsate hindadega`,
  mf`Sisustus ja huonekalut netistä. Laajin valikoima kalusteita`,
  mf`Мебель и интерьер. Наибольший выбор мебели`,
)

export { m1225 as 'meta.title' }