import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Keeldu

  <span style="color:#85c1dc;">**FI:**</span>
  Hylkää

  <span style="color:#85c1dc;">**RU:**</span>
  Отклонить
*/
/* @__NO_SIDE_EFFECTS__ */
const m1631 = msg(
  mf`Keeldu`,
  mf`Hylkää`,
  mf`Отклонить`,
)

export { m1631 as 'w.cookies.button.deny' }