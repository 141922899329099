function isOneToFiftyCharacters(string: string) {
  return string.length >= 1 && string.length <= 50
}

function isValidPostalCode(postalCode: string | null) {
  if (!postalCode) {
    return false
  }

  // digits only, length 5
  const postalCodeRegex = /^[0-9]{5}$/

  return postalCodeRegex.test(postalCode.trim())
}

function isValidFullName(fullName: string) {
  /* no digits, length 3-50 */
  const fullNameRegex = /^\D{3,50}$/

  return fullNameRegex.test(fullName)
}

function isValidPhoneNumber(phoneNumber: string) {
  // Allow leading plus, digits and special characters only ( ()-[]), length 6-16 ; can also have other text
  const phoneNumberRegex = /^\+?[0-9 ()-[\]]{5,16}$/
  // Additionally one of the characters must be a digit.
  const singleDigitRegex = /\d/

  return phoneNumberRegex.test(phoneNumber) && singleDigitRegex.test(phoneNumber)
}

function isValidEstMobilePhoneNumber(phoneNumber: string) {
  /* currently using similar logic to back-end (Util.toMobileNumber) */

  let mobileNumber = phoneNumber?.trim() || null

  if (mobileNumber === null) {
    return false
  }

  // remove the following characters from input: ( ) {} [ ] / \ + - and spaces
  mobileNumber = mobileNumber.replace(/[(){}[\]/\\\-+\s]/g, '')

  // remove leading 372 if exists
  mobileNumber = mobileNumber.startsWith('372') ? mobileNumber.substring(3) : mobileNumber

  // validate that only numbers
  const numericRegex = /^[0-9]+$/
  if (!numericRegex.test(mobileNumber)) {
    return false
  }

  // validate length
  if (mobileNumber.length < 6 || mobileNumber.length > 10) {
    return false
  }

  return mobileNumber[0] === '5'
}

function isValidFiMobilePhoneNumber(phoneNumber: string) {
  let mobileNumber = phoneNumber?.trim() || null

  if (mobileNumber === null) {
    return false
  }

  // remove the following characters from input: ( ) {} [ ] / \ + - and spaces
  mobileNumber = mobileNumber.replace(/[(){}[\]/\\\-+\s]/g, '')

  // remove leading 358 if exists
  mobileNumber = mobileNumber.startsWith('358') ? mobileNumber.substring(3) : mobileNumber

  // validate that only numbers
  const numericRegex = /^[0-9]+$/
  if (!numericRegex.test(mobileNumber)) {
    return false
  }

  // validate length
  if (mobileNumber.length < 5 || mobileNumber.length > 12) {
    return false
  }

  return mobileNumber[0] === '5' || mobileNumber[0] === '4' || mobileNumber[0] === '0'
}

function isValidEmail(email: string) {
  // classic e-mail regex
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  return emailRegex.test(email)
}

function isValidEstonianPersonalIdentityCode(identitycode: string) {
  if (!identitycode || !/^[1-6][0-9]{10}$/.test(identitycode)) {
    return false
  }

  const weights = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3]
  const n = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  let sum = 0
  const zeroCharCode = '0'.charCodeAt(0)
  for (let i = 0; i < 10; i++) {
    n[i] = identitycode.charCodeAt(i) - zeroCharCode
    sum += n[i] * weights[i]
  }
  sum %= 11

  if (sum === 10) {
    sum = 0
    for (let i = 0; i < 10; i++) {
      sum += n[i] * weights[i + 2]
    }

    sum %= 11

    if (sum === 10) {
      sum = 0
    }
  }

  return sum === identitycode.charCodeAt(10) - zeroCharCode
}

function isValidEstonianDocumentNumber(documentNumber: string) {
  const documentNumberRegex = /^[A-Z]{1,2}[0-9]{4,30}$/

  return documentNumberRegex.test(documentNumber)
}

function isValidName(string: string) {
  return string.length >= 1 && string.length <= 100
}

function isValidNumeric(string: string) {
  // Format: <sign>? <integer> <decimal separator>? <integer>?
  const numericRegex = /^[+-]?\d+([,.]\d*)?$/

  return numericRegex.test(string)
}

function isValidInteger(integer: string) {
  const integerRegex = /^\d+$/

  return integerRegex.test(integer)
}

export {
  isOneToFiftyCharacters,
  isValidPostalCode,
  isValidFullName,
  isValidPhoneNumber,
  isValidEstMobilePhoneNumber,
  isValidFiMobilePhoneNumber,
  isValidEmail,
  isValidEstonianPersonalIdentityCode,
  isValidEstonianDocumentNumber,
  isValidName,
  isValidNumeric,
  isValidInteger,
}
