import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kõik %s

  <span style="color:#85c1dc;">**FI:**</span>
  Kaikki %s

  <span style="color:#85c1dc;">**RU:**</span>
  Все %s
*/
/* @__NO_SIDE_EFFECTS__ */
const m549 = msg(
  mf`Kõik %s`,
  mf`Kaikki %s`,
  mf`Все %s`,
)

export { m549 as 'w.header.menu.view-all' }