import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Tere tulemast.

  <span style="color:#85c1dc;">**FI:**</span>
  Tervetuloa, hyvä ystävä

  <span style="color:#85c1dc;">**RU:**</span>
  Добро пожаловать!
*/
/* @__NO_SIDE_EFFECTS__ */
const m400 = msg(
  mf`Tere tulemast.`,
  mf`Tervetuloa, hyvä ystävä`,
  mf`Добро пожаловать!`,
)

export { m400 as 'w.login.welcome' }