<template>
  <li ref="hoverParent" class="relative" @focusin="onFocus" @focusout="onFocusOut" @mouseleave="onMouseLeave">
    <template v-if="loggedIn">
      <div class="flex items-center" @mouseenter="onMouseEnter">
        <NuxtLink to="/account/main" class="group hidden items-center md:flex" :aria-label="welcomeLabel">
          <div v-if="!props.hasScrolled" class="mr-1 hidden whitespace-nowrap text-sm font-semibold md:block lg:ml-2">{{ welcomeLabel }}</div>
          <Icon name="account" class="box-content flex size-6 rounded-full p-2 transition-colors group-hover:bg-background-light group-hover:text-icon-active" />
        </NuxtLink>
        <button class="flex rounded-full p-2 transition-colors hover:bg-background-light hover:text-icon-active md:hidden" :aria-label="welcomeLabel" @click="onAccountButtonClick">
          <Icon class="size-6" name="account" />
          <span class="sr-only">{{ t['w.menu.minukonto']() }}</span>
        </button>
      </div>
      <div
        class="absolute right-0 top-full z-30 hidden w-[22.5rem] pt-2"
        :class="{
          'md:block': isMenuOpen,
        }"
      >
        <AccountMenu class="rounded-2xl bg-background shadow-04" :name-label="welcomeLabel" />
      </div>
    </template>
    <template v-else>
      <div class="flex cursor-pointer items-center" @mouseenter="onMouseEnter">
        <NuxtLink class="group flex items-center" :aria-label="welcomeLabel" @click="loginModalStore.open(t['w.login.welcome']())">
          <div v-if="!props.hasScrolled" class="mr-1 hidden whitespace-nowrap text-sm font-semibold md:block lg:ml-2">{{ welcomeLabel }}</div>
          <Icon class="box-content flex size-6 rounded-full p-2 transition-colors group-hover:bg-background-light group-hover:text-icon-active" name="account" />
        </NuxtLink>
      </div>
      <div
        class="absolute right-0 top-full z-30 hidden w-[22.5rem] pt-2"
        :class="{
          'md:block': isMenuOpen,
        }"
      >
        <AccountMenu class="rounded-2xl bg-background shadow-04" />
      </div>
    </template>
  </li>
</template>

<script setup lang="ts">
import Icon from '~/components/icon/Icon.vue'
import AccountMenu from '~/components/header/AccountMenu.vue'
import { useHeaderStore } from '~/stores/header'
import { useLoginModalStore } from '~/stores/login-modal'
import * as t from '~/messages'

const props = defineProps<{ hasScrolled: boolean }>()

const hoverParent = useTemplateRef('hoverParent')

const headerStore = useHeaderStore()
const loginModalStore = useLoginModalStore()
const isMenuOpen = ref(false)

const onMouseEnter = () => {
  isMenuOpen.value = true
}

const onMouseLeave = () => {
  isMenuOpen.value = false
}

const onFocus = () => {
  isMenuOpen.value = true
}

const onFocusOut = useSafeFocusEvent((e) => {
  if (hoverParent.value) {
    const isFocusInsideParent = hoverParent.value.contains(e.relatedTarget)

    isMenuOpen.value = isFocusInsideParent
  }
})

const onAccountButtonClick = () => {
  if (headerStore) {
    headerStore.openMenu(t['w.header.menu.account-menu-name']())
  }
}

const user = useUserState()

const loggedIn = !!user.value
const userName = user.value?.fullName || ''
const welcomeLabel = computed(() => {
  return loggedIn ? t['w.header.menu.greeting']() + ' ' + userName : t['w.header.menu.login']()
})
</script>
