import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Salvesta eelistused

  <span style="color:#85c1dc;">**FI:**</span>
  Tallenna asetukset

  <span style="color:#85c1dc;">**RU:**</span>
  Сохранить настройки
*/
/* @__NO_SIDE_EFFECTS__ */
const m445 = msg(
  mf`Salvesta eelistused`,
  mf`Tallenna asetukset`,
  mf`Сохранить настройки`,
)

export { m445 as 'w.cookies.button.save' }