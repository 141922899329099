<template>
  <VueFinalModal v-model="isOpen" v-bind="$attrs" :z-index-base="1000000" esc-to-close lock-scroll content-class="pointer-events-none">
    <div class="fixed inset-0 flex">
      <div class="flex max-h-full w-full items-center justify-center sm:p-4">
        <div
          class="pointer-events-auto relative flex size-full max-h-full flex-col overflow-hidden bg-white p-6 pb-4 text-left sm:my-8 sm:h-auto sm:max-w-[800px] sm:rounded-3xl sm:p-10"
        >
          <div class="overflow-auto px-4">
            <LoginView v-if="currentView === 'login'" :introduction-text="text" @register-click="currentView = 'register'" />
            <RegisterView v-else-if="currentView === 'register'" @login-click="currentView = 'login'" />
          </div>

          <button class="absolute right-4 top-4 flex rounded-full p-2 transition-colors hover:bg-background-none-hover active:bg-background-none-active" @click="isOpen = false">
            <span class="sr-only">{{ t['w.modal.close']() }}</span>
            <Icon name="close" class="size-6" />
          </button>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import { useLoginModalStore } from '~/stores/login-modal'

import LoginView from '~/components/login-modal/views/LoginView.vue'
import Icon from '~/components/icon/Icon.vue'
import RegisterView from '~/components/login-modal/views/RegisterView.vue'
import * as t from '~/messages'

const loginModalStore = useLoginModalStore()
const currentView = computed({
  get() {
    return loginModalStore.getCurrentView()
  },
  set(newValue) {
    loginModalStore.setCurrentView(newValue)
  },
})

const isOpen = computed({
  get() {
    return loginModalStore.getIsOpen()
  },
  set(value) {
    loginModalStore.setIsOpen(value)
  },
})

const text = computed(() => loginModalStore.getText())
</script>
