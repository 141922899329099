<template>
  <label
    class="group flex cursor-pointer"
    :class="{
      'items-center': props.verticalAlign === 'center',
    }"
  >
    <input
      v-model="modelValue"
      class="form-radio mr-1.5 cursor-pointer text-background hover:border-border-hover"
      :class="{
        'size-4': !props.isSmall,
        'size-3': props.isSmall,
        'mt-1': props.verticalAlign === 'top',
      }"
      :value="props.value"
      type="radio"
      :name="props.name"
      :aria-label="props.label"
    />
    <slot />
  </label>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    label?: string
    name: string
    value: string
    isSmall?: boolean
    verticalAlign?: 'top' | 'center'
  }>(),
  {
    isSmall: false,
    label: '',
    verticalAlign: 'center',
  }
)

const modelValue = defineModel<string>({ required: true })
</script>
