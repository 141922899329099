import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Lk.

  <span style="color:#85c1dc;">**FI:**</span>
  S.

  <span style="color:#85c1dc;">**RU:**</span>
  Стр.
*/
/* @__NO_SIDE_EFFECTS__ */
const m349 = msg(
  mf`Lk.`,
  mf`S.`,
  mf`Стр.`,
)

export { m349 as 'meta.title.page' }