import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kõik õigused kaitstud

  <span style="color:#85c1dc;">**FI:**</span>
  Kaikki oikeudet pidätetään

  <span style="color:#85c1dc;">**RU:**</span>
  Bce права защищены
*/
/* @__NO_SIDE_EFFECTS__ */
const m1885 = msg(
  mf`Kõik õigused kaitstud`,
  mf`Kaikki oikeudet pidätetään`,
  mf`Bce права защищены`,
)

export { m1885 as 'w.footer.copyright.text' }