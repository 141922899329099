import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kohanda valikud

  <span style="color:#85c1dc;">**FI:**</span>
  Muokkaa vaihtoehtoja

  <span style="color:#85c1dc;">**RU:**</span>
  Настроить параметры
*/
/* @__NO_SIDE_EFFECTS__ */
const m1203 = msg(
  mf`Kohanda valikud`,
  mf`Muokkaa vaihtoehtoja`,
  mf`Настроить параметры`,
)

export { m1203 as 'w.cookies.button.customize' }