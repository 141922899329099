<script setup lang="ts">
const {
  public: {
    recaptcha: { v2SiteKey },
  },
} = useRuntimeConfig()

const recaptchaModel = defineModel<string | null>({ default: null })

const containerRef = useTemplateRef('containerRef')

const { onLoaded } = useRecaptcha()

onMounted(() => {
  onLoaded((grecaptcha) => {
    grecaptcha.render(containerRef.value!, {
      sitekey: v2SiteKey,
      callback: (token) => {
        recaptchaModel.value = token
      },
    })
  })
})
</script>

<template>
  <div ref="containerRef" />
</template>
