import { withQuery } from 'ufo'

// TODO(Indrek): Simplify to string & inline if possible
type EmailType = string | null

declare global {
  interface Window {
    criteo_q: unknown[]
  }
}

export const useCriteoScript = () => {
  const {
    public: { criteoPartnerId, countryId },
  } = useRuntimeConfig()

  if (countryId !== 2 || !criteoPartnerId) {
    return
  }

  return useScript(
    {
      src: withQuery('https://dynamic.criteo.com/js/ld/ld.js', { a: criteoPartnerId }),
      crossorigin: false,
    },
    {
      use() {
        return { criteo_q: window.criteo_q }
      },
      stub: import.meta.client
        ? undefined
        : ({ fn }) => {
            return fn === 'criteo_q' ? [] : void 0
          },
      beforeInit: () => {
        if (import.meta.client) {
          window.criteo_q = window.criteo_q || []
        }
      },
    }
  )
}

export const useCriteo = () => {
  const {
    public: { criteoPartnerId },
  } = useRuntimeConfig()

  const script = useCriteoScript()

  const productImpressions = (email: EmailType, products: number[]) => {
    if (import.meta.client) {
      script?.proxy.criteo_q.push(...common(email), { event: 'viewList', item: products.map((product) => product.toString()) })
    }
  }

  const productImpression = (email: EmailType, productId: number) => {
    if (import.meta.client) {
      script?.proxy.criteo_q.push(...common(email), { event: 'viewItem', item: productId.toString() })
    }
  }

  const home = (email: EmailType) => {
    if (import.meta.client) {
      script?.proxy.criteo_q.push(...common(email), { event: 'viewHome' })
    }
  }

  const conversion = (email: EmailType, transactionId: string, products: { productId: string; price: string; quantity: string }[]) => {
    if (import.meta.client) {
      script?.proxy.criteo_q.push(...common(email), {
        event: 'trackTransaction',
        id: transactionId,
        item: products.map((product) => ({ id: product.productId, price: product.price, quantity: product.quantity })),
      })
    }
  }

  const common = (email: EmailType) => {
    return [
      { event: 'setAccount', account: criteoPartnerId },
      { event: 'setEmail', email: email ?? '', hash_method: 'md5' },
      { event: 'setSiteType', type: /iPad/.test(navigator.userAgent) ? 't' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd' },
    ]
  }

  return {
    productImpressions,
    productImpression,
    home,
    conversion,
  }
}

// Userspace
export const useCriteoHomeEvent = () => {
  const user = useUserState()
  const { home } = useCriteo()

  const email = user.value?.emailMd5 ?? null

  home(email)
}
