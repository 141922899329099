import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Palun nõustu järelmaksu tingimustega

  <span style="color:#85c1dc;">**FI:**</span>
  Hyväksy ehdot

  <span style="color:#85c1dc;">**RU:**</span>
  Пожалуйста, примите условия оплаты в рассрочку
*/
/* @__NO_SIDE_EFFECTS__ */
const m1498 = msg(
  mf`Palun nõustu järelmaksu tingimustega`,
  mf`Hyväksy ehdot`,
  mf`Пожалуйста, примите условия оплаты в рассрочку`,
)

export { m1498 as 'w.basket.after_payment.submit_button_agree_to_terms' }