import { useLocalStorage } from '@vueuse/core'

const LAST_VIEWED_PRODUCTS = 'lastViewedProducts'
const MAX_LAST_VIEWED_PRODUCTS = 10

interface LastViewedProduct {
  id: number
  title: string
  url: string
  image: string
}

export const useLastViewedProducts = () => {
  const lastViewedProducts = useLocalStorage(LAST_VIEWED_PRODUCTS, () => [] as LastViewedProduct[])

  const addToLastViewedProducts = (product: LastViewedProduct) => {
    if (!product.id || !product.title || !product.url || !product.image) {
      return
    }

    // Shift already existing product to first place and limit the length.
    lastViewedProducts.value = [product, ...lastViewedProducts.value.filter((lvp) => lvp.id !== product.id)].slice(0, MAX_LAST_VIEWED_PRODUCTS)
  }

  return {
    lastViewedProducts,
    addToLastViewedProducts,
  }
}
