import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Tooted

  <span style="color:#85c1dc;">**FI:**</span>
  Tuotteet

  <span style="color:#85c1dc;">**RU:**</span>
  Товары
*/
/* @__NO_SIDE_EFFECTS__ */
const m512 = msg(
  mf`Tooted`,
  mf`Tuotteet`,
  mf`Товары`,
)

export { m512 as 'w.search.products' }