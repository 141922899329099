import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kehtiv salasõna

  <span style="color:#85c1dc;">**FI:**</span>
  Voimassaoleva salasana

  <span style="color:#85c1dc;">**RU:**</span>
  Текущий пароль
*/
/* @__NO_SIDE_EFFECTS__ */
const m313 = msg(
  mf`Kehtiv salasõna`,
  mf`Voimassaoleva salasana`,
  mf`Текущий пароль`,
)

export { m313 as 'w.klient.parool' }