<template>
  <div class="rounded-lg bg-background-light px-6 pb-2 pt-6">
    <div class="text-subheading">
      {{ t['w.account.greeting']({ name: user.fullName }) }}
    </div>
    <span class="flex justify-between border-b border-border-light py-3">
      <span class="text-base">{{ t['w.account_menu.credit']() }}</span>
      <Price class="text-subheading" :amount="userCreditBalance" />
    </span>

    <ul class="my-1">
      <li v-for="(entry, index) in accountMenu" :key="index">
        <NuxtLink :to="entry.url" active-class="text-text-strong" class="inline-flex w-full items-center py-1.5 hover:text-link-strong">
          <span v-if="entry.attention" class="mr-1.5 inline-block size-1.5 rounded-full bg-icon-bright" :title="t['w.account_menu.attention']()" />
          {{ entry.name }}
        </NuxtLink>
      </li>
      <li class="mt-3 border-t border-border-light pt-3">
        <button class="inline-block w-full py-2.5 text-left hover:text-link-strong" @click="logout">{{ t['w.menu.logout']() }}</button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import Price from '~/components/price/Price.vue'
import * as t from '~/messages'

const { accountMenu } = useAccountMenu()
const user = useUserState()

const userCreditBalance: number = (user.value?.balance || 0) as number

const logout = () => {
  $fetch<unknown>('/api/account/logout').then(() => {
    window.location.replace('/')
  })
}
</script>
