import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Lisa

  <span style="color:#85c1dc;">**FI:**</span>
  Lisää suosikkisi

  <span style="color:#85c1dc;">**RU:**</span>
  Добавить в избранное
*/
/* @__NO_SIDE_EFFECTS__ */
const m888 = msg(
  mf`Lisa`,
  mf`Lisää suosikkisi`,
  mf`Добавить в избранное`,
)

export { m888 as 'w.wishlist.add' }