<template>
  <textarea
    :id="name"
    class="form-textarea mt-1 block min-h-40 w-full rounded border-border-medium focus:border-border-hover focus:ring focus:ring-border-light/50 md:text-base"
    :class="{
      'border-border-medium': !isError,
      'border-text-attention': isError,
    }"
    :name="name"
    :value="modelValue"
    :autocomplete="autocomplete"
    :required="required"
    :rows="rows"
    :cols="cols"
    :placeholder="placeholder"
    @input="input"
    @invalid="invalid"
    @keypress="validate"
  />
</template>

<script setup lang="ts">
import * as t from '~/messages'

const props = withDefaults(
  defineProps<{
    name: string
    label?: string
    placeholder?: string
    required?: boolean
    isError?: boolean
    modelValue?: string
    autocomplete?: string
    validation?: string
    rows?: string
    cols?: string
  }>(),
  {
    required: false,
    isError: false,
    label: undefined,
    placeholder: '',
    modelValue: undefined,
    autocomplete: undefined,
    validation: undefined,
    rows: undefined,
    cols: undefined,
  }
)

const emit = defineEmits<{
  'update:modelValue': [newValue: string]
}>()

const input = useSafeEvent<HTMLInputElement>((event) => {
  emit('update:modelValue', event.target.value)
  event.target.setCustomValidity('')
})

const invalid = useSafeEvent<HTMLInputElement>((event) => {
  if (props.required && (!props.modelValue || !props.modelValue.length)) {
    event.target.setCustomValidity(t['floating-input.required']())
  }
})

const validate = (event: KeyboardEvent) => {
  if (props.validation && !new RegExp(props.validation).test(event.key)) {
    return event.preventDefault()
  }
}
</script>
