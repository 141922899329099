import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Omniva pakiautomaat

  <span style="color:#85c1dc;">**FI:**</span>
  

  <span style="color:#85c1dc;">**RU:**</span>
  Терминал Omniva
*/
/* @__NO_SIDE_EFFECTS__ */
const m729 = msg(
  mf`Omniva pakiautomaat`,
  mf``,
  mf`Терминал Omniva`,
)

export { m729 as 'link.omniva.alt' }