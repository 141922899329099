<template>
  <button
    type="button"
    class="flex w-full items-center justify-center rounded-button bg-facebook px-10 py-2 text-button-m font-medium text-text-inverted transition-colors hover:bg-facebook-hover active:bg-facebook"
    :class="{
      'md:py-2': !props.isLarge,
    }"
    @click="facebookLogin"
  >
    <Icon name="facebook" class="mr-3 size-6" /> {{ t['w.global.register_facebook']() }}
  </button>
</template>

<script setup lang="ts">
import * as t from '~/messages'

const props = withDefaults(
  defineProps<{
    isLarge?: boolean
  }>(),
  {
    isLarge: false,
  }
)
const { login: facebookLogin } = useFacebookLogin()
</script>
