import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kontakttelefon

  <span style="color:#85c1dc;">**FI:**</span>
  Yhteyspuhelinnumero

  <span style="color:#85c1dc;">**RU:**</span>
  Контактный телефон
*/
/* @__NO_SIDE_EFFECTS__ */
const m61 = msg(
  mf`Kontakttelefon`,
  mf`Yhteyspuhelinnumero`,
  mf`Контактный телефон`,
)

export { m61 as 'w.ostukorv.telefon' }