import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  /info/on24\_jaerelmaksu\_mueuegilepingu\_ueldtingimused

  <span style="color:#85c1dc;">**FI:**</span>
  https://www.avarda.com/media/1381/luottoehdot-ja-vakiomuotoiset-eurooppalaiset-kuluttajaluottotiedot.pdf

  <span style="color:#85c1dc;">**RU:**</span>
  /info/on24\_общие\_условия\_договора\_в\_рассрочку
*/
/* @__NO_SIDE_EFFECTS__ */
const m577 = msg(
  mf`/info/on24_jaerelmaksu_mueuegilepingu_ueldtingimused`,
  mf`https://www.avarda.com/media/1381/luottoehdot-ja-vakiomuotoiset-eurooppalaiset-kuluttajaluottotiedot.pdf`,
  mf`/info/on24_общие_условия_договора_в_рассрочку`,
)

export { m577 as 'w.basket.after_payment.agree_to_terms_link_url' }