import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Uus klient?

  <span style="color:#85c1dc;">**FI:**</span>
  Uusi asiakas?

  <span style="color:#85c1dc;">**RU:**</span>
  Новый клиент?
*/
/* @__NO_SIDE_EFFECTS__ */
const m1259 = msg(
  mf`Uus klient?`,
  mf`Uusi asiakas?`,
  mf`Новый клиент?`,
)

export { m1259 as 'header.new-customer' }