import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Soovin saada informatsiooni uutest toodetest ja sooduspakkumistest.

  <span style="color:#85c1dc;">**FI:**</span>
  Haluan saada tietoa uusista tuotteista ja tarjouksista.

  <span style="color:#85c1dc;">**RU:**</span>
  Я хочу получать информацию о новых продуктах и ​​специальных предложениях.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1109 = msg(
  mf`Soovin saada informatsiooni uutest toodetest ja sooduspakkumistest.`,
  mf`Haluan saada tietoa uusista tuotteista ja tarjouksista.`,
  mf`Я хочу получать информацию о новых продуктах и ​​специальных предложениях.`,
)

export { m1109 as 'w.register.subscribe' }