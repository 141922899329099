import type { Link } from '~/types/link'
import type { HeaderLink } from '~/types/header-link'
import type { FooterSection, PreHeaderEntry, User, InformationModal, Newsletter, SiteHat } from '~/types/initial-response'
import type { ValuePropSection } from '~/types/value-prop-section'

type InitSignature<T> = () => T | Ref<T>

export const useOrderHistoryAttentionState = (init?: InitSignature<boolean>) => useState('historyAttention', init)
export const useAccountBalanceAttentionState = (init?: InitSignature<boolean>) => useState('balanceAttention', init)

export const useUserState = (init?: InitSignature<User>) => useState('user', init)

export const useBasketState = (init?: InitSignature<number>) => useState('basket', init)

// export const useBasketAlsoBuyState = (init?: InitSignature<any[]>) => useState('basketAlsoBuy', init)

export const useCurrentTransportStepState = (init?: InitSignature<'transport' | 'contact' | 'payment'>) => useState('currentTransportStep', init)

export const useHeaderLinksState = (init?: InitSignature<HeaderLink[]>) => useState('headerLinks', init)

export const useFooterLinksState = (init?: InitSignature<FooterSection[] | null>) => useState('footerLinks', init)

export const useWishlistState = (init?: InitSignature<number>) => useState('wishlist', init)

export const useRequiresCaptchaState = (init?: InitSignature<boolean>) => useState('requiresCaptcha', init)

export const useIsFilterOpenState = () => useState('isFilterOpen', () => false)

export const useSocialsState = (init?: InitSignature<Link[]>) => useState('socials', init)

export const useIsAddReviewOpenState = () => useState('isAddReviewOpen', () => false)

export const useSearchTermState = () => useState('searchTerm', () => '')

export const useSearchOpenState = () => useState('searchOpen', () => false)

export const usePreHeaderState = (init?: InitSignature<PreHeaderEntry[]>) => useState('preHeader', init)

export const useHeaderScrollBottomPosState = () => useState('headerScrollBottomPos', () => 0)

export const useNewsletterState = (init?: InitSignature<Newsletter | null>) => useState('newsletter', init)

export const useValuePropSectionsState = (init?: InitSignature<ValuePropSection[] | null>) => useState('valuePropSections', init)

export const useSuccessfulRegistrationEmailState = () => useState('successfulRegistrationEmailState', () => '')

export const useSiteHatsState = (init?: InitSignature<SiteHat[] | null>) => useState('siteHats', init)

export const useInformationModalState = (init?: InitSignature<InformationModal | null>) => useState('informationModal', init)
