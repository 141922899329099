import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Igakuine makse

  <span style="color:#85c1dc;">**FI:**</span>
  Kuukausierä alk.

  <span style="color:#85c1dc;">**RU:**</span>
  Ежемесячная плата
*/
/* @__NO_SIDE_EFFECTS__ */
const m1856 = msg(
  mf`Igakuine makse`,
  mf`Kuukausierä alk.`,
  mf`Ежемесячная плата`,
)

export { m1856 as 'w.basket.after_payment.monthly_payment_label' }