import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  © 2024 ON24 AS

  <span style="color:#85c1dc;">**FI:**</span>
  © 2024 On24 Sisustus OY

  <span style="color:#85c1dc;">**RU:**</span>
  © 2024 ON24 AS
*/
/* @__NO_SIDE_EFFECTS__ */
const m1238 = msg(
  mf`© 2024 ON24 AS`,
  mf`© 2024 On24 Sisustus OY`,
  mf`© 2024 ON24 AS`,
)

export { m1238 as 'w.footer.copyright' }