import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Ava otsing

  <span style="color:#85c1dc;">**FI:**</span>
  Avaa haku

  <span style="color:#85c1dc;">**RU:**</span>
  Открыть поиск
*/
/* @__NO_SIDE_EFFECTS__ */
const m1991 = msg(
  mf`Ava otsing`,
  mf`Avaa haku`,
  mf`Открыть поиск`,
)

export { m1991 as 'search.open' }