import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Turundus

  <span style="color:#85c1dc;">**FI:**</span>
  Markkinointi

  <span style="color:#85c1dc;">**RU:**</span>
  Маркетинг
*/
/* @__NO_SIDE_EFFECTS__ */
const m53 = msg(
  mf`Turundus`,
  mf`Markkinointi`,
  mf`Маркетинг`,
)

export { m53 as 'w.cookies.customize.marketing.title' }