import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  /info/tarneaeg\_ja\_transport

  <span style="color:#85c1dc;">**FI:**</span>
  https://www.posti.fi/yritysasiakkaat/

  <span style="color:#85c1dc;">**RU:**</span>
  /info/srok\_postavki\_i\_transport
*/
/* @__NO_SIDE_EFFECTS__ */
const m1024 = msg(
  mf`/info/tarneaeg_ja_transport`,
  mf`https://www.posti.fi/yritysasiakkaat/`,
  mf`/info/srok_postavki_i_transport`,
)

export { m1024 as 'link.smartpost.href' }