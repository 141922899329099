import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Me hindame su privaatsust

  <span style="color:#85c1dc;">**FI:**</span>
  Arvostamme yksityisyyttäsi

  <span style="color:#85c1dc;">**RU:**</span>
  Мы ценим Вашу конфиденциальность
*/
/* @__NO_SIDE_EFFECTS__ */
const m7 = msg(
  mf`Me hindame su privaatsust`,
  mf`Arvostamme yksityisyyttäsi`,
  mf`Мы ценим Вашу конфиденциальность`,
)

export { m7 as 'w.cookies.customize.title' }