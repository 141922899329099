export const useKelkooLeadTag = () => {
  const {
    public: { countryId },
  } = useRuntimeConfig()

  if (countryId !== 2) {
    return
  }

  return useScript({
    src: 'https://s.kk-resources.com/leadtag.js',
    crossorigin: false,
  })
}
