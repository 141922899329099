import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Turvaline tellimine

  <span style="color:#85c1dc;">**FI:**</span>
  Turvallinen tilaaminen

  <span style="color:#85c1dc;">**RU:**</span>
  Безопасный заказ
*/
/* @__NO_SIDE_EFFECTS__ */
const m1239 = msg(
  mf`Turvaline tellimine`,
  mf`Turvallinen tilaaminen`,
  mf`Безопасный заказ`,
)

export { m1239 as 'w.checkout.safe_order' }