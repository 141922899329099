import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kontaktisiku ees- ja perenimi

  <span style="color:#85c1dc;">**FI:**</span>
  Tilauksen vastaanottaja

  <span style="color:#85c1dc;">**RU:**</span>
  Контактное лицо
*/
/* @__NO_SIDE_EFFECTS__ */
const m226 = msg(
  mf`Kontaktisiku ees- ja perenimi`,
  mf`Tilauksen vastaanottaja`,
  mf`Контактное лицо`,
)

export { m226 as 'w.ostukorv.kontaktisik' }