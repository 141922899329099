import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Otsi märksõna

  <span style="color:#85c1dc;">**FI:**</span>
  Etsi hakusana

  <span style="color:#85c1dc;">**RU:**</span>
  Поиск ключевого слова
*/
/* @__NO_SIDE_EFFECTS__ */
const m754 = msg(
  mf`Otsi märksõna`,
  mf`Etsi hakusana`,
  mf`Поиск ключевого слова`,
)

export { m754 as 'w.search.search-keyword' }