import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  /info/unustasite\_salasona

  <span style="color:#85c1dc;">**FI:**</span>
  /info/unohdit\_salasanan

  <span style="color:#85c1dc;">**RU:**</span>
  /info/забыли\_пароль
*/
/* @__NO_SIDE_EFFECTS__ */
const m1905 = msg(
  mf`/info/unustasite_salasona`,
  mf`/info/unohdit_salasanan`,
  mf`/info/забыли_пароль`,
)

export { m1905 as 'w.login.forgot-password.url' }