import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Tellija info

  <span style="color:#85c1dc;">**FI:**</span>
  Tilaajan tiedot

  <span style="color:#85c1dc;">**RU:**</span>
  Информация о заказчике
*/
/* @__NO_SIDE_EFFECTS__ */
const m1220 = msg(
  mf`Tellija info`,
  mf`Tilaajan tiedot`,
  mf`Информация о заказчике`,
)

export { m1220 as 'w.basket.contact_section' }