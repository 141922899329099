<template>
  <div
    class="flex justify-between pb-4 pt-6 text-subheading transition-colors"
    :class="{
      'border-b border-border-medium': props.hasBorder,
      'font-medium': !props.isDisabled,
      'text-text-disabled': props.isDisabled,
    }"
  >
    <div>
      {{ props.number ? props.number + '. ' : '' }}{{ props.title }}
      <div
        class="mt-2 text-sm text-text-disabled"
        :class="{
          'ml-[21px]': props.number,
        }"
      >
        <slot />
      </div>
    </div>
    <NuxtLink v-if="link || $attrs.onClick" :to="link" class="cursor-pointer text-base text-text underline hover:no-underline" @click="$attrs.click">
      {{ t['w.basket.change_previous_step']() }}
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import * as t from '~/messages'

const props = withDefaults(
  defineProps<{
    title: string
    link?: string
    number: string
    isDisabled?: boolean
    hasBorder?: boolean
  }>(),
  {
    link: undefined,
    isDisabled: true,
    hasBorder: true,
  }
)
</script>
