import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Lisatud

  <span style="color:#85c1dc;">**FI:**</span>
  Lisätyt

  <span style="color:#85c1dc;">**RU:**</span>
  Добавлено
*/
/* @__NO_SIDE_EFFECTS__ */
const m1649 = msg(
  mf`Lisatud`,
  mf`Lisätyt`,
  mf`Добавлено`,
)

export { m1649 as 'w.global.product.favourite_added' }