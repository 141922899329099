import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Uus

  <span style="color:#85c1dc;">**FI:**</span>
  Uusi

  <span style="color:#85c1dc;">**RU:**</span>
  Новый
*/
/* @__NO_SIDE_EFFECTS__ */
const m878 = msg(
  mf`Uus`,
  mf`Uusi`,
  mf`Новый`,
)

export { m878 as 'w.account_menu.attention' }