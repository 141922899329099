import { createSharedComposable } from '@vueuse/shared'

export const sharedComposable = <T, R, Fn extends (...args: T[]) => R>(composable: Fn): Fn => {
  const key: WeakKey = {}

  return <Fn>((...args) => {
    const { $sharedComposable } = useNuxtApp()

    let fn = $sharedComposable.get(key)

    if (!fn) {
      fn = createSharedComposable(composable)
      $sharedComposable.set(key, fn)
    }

    return fn(...args)
  })
}
