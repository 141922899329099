type CookieConsentValue = null | string
const DAYS_TO_SECONDS = 24 * 60 * 60
const CURRENT_CONSENT_VERSION = 'a'

export const useCookieConsent = sharedComposable(() => {
  const cookie = useCookie<CookieConsentValue>('cookieConsent', {
    maxAge: DAYS_TO_SECONDS * 60,
    sameSite: 'none',
    secure: true,
    default: () => null,
  })

  const consentProcessPerformed = computed(() => {
    return typeof cookie.value === 'string' && cookie.value.length === 4 && cookie.value[0] === CURRENT_CONSENT_VERSION
  })

  const mandatoryCookiesAccepted = computed(() => true) // Implied to be true
  const functionalCookiesAccepted = computed(() => consentProcessPerformed.value && cookie.value![1] === '1')
  const analyticalCookiesAccepted = computed(() => consentProcessPerformed.value && cookie.value![2] === '1')
  const marketingCookiesAccepted = computed(() => consentProcessPerformed.value && cookie.value![3] === '1')

  // TODO: Cookie mutation should be performed on backend to extend the cookie's lifetime
  const saveConsent = (consents: { functionalCookiesAccepted: boolean; analyticalCookiesAccepted: boolean; marketingCookiesAccepted: boolean }) => {
    cookie.value =
      CURRENT_CONSENT_VERSION +
      (consents.functionalCookiesAccepted ? '1' : '0') +
      (consents.analyticalCookiesAccepted ? '1' : '0') +
      (consents.marketingCookiesAccepted ? '1' : '0')
  }

  const functionalCookieConsentScriptTrigger = useScriptTriggerConsent({
    consent: functionalCookiesAccepted,
    postConsentTrigger: 'onNuxtReady',
  })

  const analyticalCookieConsentScriptTrigger = useScriptTriggerConsent({
    consent: analyticalCookiesAccepted,
    postConsentTrigger: 'onNuxtReady',
  })

  const marketingCookieConsentScriptTrigger = useScriptTriggerConsent({
    consent: marketingCookiesAccepted,
    postConsentTrigger: 'onNuxtReady',
  })

  return {
    cookie,
    consentProcessPerformed,
    mandatoryCookiesAccepted,
    functionalCookiesAccepted,
    analyticalCookiesAccepted,
    marketingCookiesAccepted,
    functionalCookieConsentScriptTrigger,
    analyticalCookieConsentScriptTrigger,
    marketingCookieConsentScriptTrigger,
    saveConsent,
  }
})
