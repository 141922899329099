<template>
  <VueFinalModal
    v-if="isActive"
    v-model="isOpen"
    content-class="pointer-events-none fixed inset-0 flex w-full justify-end sm:left-auto md:bottom-auto md:left-1/2 md:right-auto md:top-1/2 md:size-auto md:-translate-x-1/2 md:-translate-y-1/2 md:items-center md:justify-center"
    :z-index-base="100000"
    esc-to-close
  >
    <div class="pointer-events-auto md:flex md:items-center md:justify-end">
      <div
        class="relative flex max-h-window-height min-h-full w-full flex-col overflow-auto bg-background-light sm:w-[360px] md:min-h-[400px] md:w-[800px] md:flex-row md:rounded-3xl"
      >
        <div v-if="imageSrc" class="relative h-60 shrink-0 md:h-auto md:basis-2/5">
          <OnImg class="absolute inset-0 size-full object-cover" :src="imageSrc" :alt="imageAlt" sizes="sm:400px lg:600px" />
        </div>
        <div class="bg-background-light md:relative md:grow">
          <button
            class="absolute right-3 top-3 flex size-10 items-center justify-center rounded-full bg-background transition-colors hover:bg-background-none-hover active:bg-background-none-active md:bg-background-light"
            @click="closeModal"
          >
            <span class="sr-only">{{ t['w.modal.close']() }}</span>
            <Icon name="close" class="size-6" />
          </button>
          <div
            class="flex h-full flex-col justify-center px-5 sm:bg-background-light md:p-10 md:py-16"
            :class="{
              'py-6': imageSrc,
              'pt-16 md:pl-11 md:pr-16 md:pt-10': !imageSrc,
            }"
          >
            <h1 class="text-h2 md:text-h1">{{ title }}</h1>
            <div class="text mt-4 text-lg" v-html="text" />
            <Button class="btn-lg btn-rounded mt-10 md:mr-auto md:mt-8" icon-name="arrow-right" :link="buttonLink !== '#' ? buttonLink : undefined" @click="closeModal">
              {{ buttonText }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import Button from '~/components/button/Button.vue'
import OnImg from '~/components/on-img/OnImg.vue'
import { VueFinalModal } from 'vue-final-modal'
import * as t from '~/messages'
import { useInformationModalState } from '~/composables/states'

const modalState = useInformationModalState()

const isActive = modalState.value !== null
const title = modalState.value?.title ?? ''
const text = modalState.value?.text ?? ''
const buttonText = modalState.value?.buttonText ?? ''
const buttonLink = modalState.value?.buttonLink ?? ''
const imageSrc = modalState.value?.imageSrc ?? ''
const imageAlt = modalState.value?.imageAlt ?? ''
const appearTime = 3000

const isOpen = ref(false)

const daysToSeconds = 24 * 60 * 60

const cookie = useCookie('informationModalClosed', {
  maxAge: 1 * daysToSeconds,
  sameSite: 'none',
  secure: true,
  default: () => false,
})

const closeModal = () => {
  isOpen.value = false
}

onMounted(() => {
  if (!isActive) {
    return
  }

  const route = useRoute()

  const end = watch(
    () => route.path,
    () => {
      if (!cookie.value) {
        setTimeout(() => {
          isOpen.value = true
        }, appearTime)
        end()
        cookie.value = true
      }
    }
  )
})
</script>
