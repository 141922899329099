import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Oled juba kasutaja?

  <span style="color:#85c1dc;">**FI:**</span>
  Olet jo rekisteröitynyt

  <span style="color:#85c1dc;">**RU:**</span>
  Уже являетесь пользователем?
*/
/* @__NO_SIDE_EFFECTS__ */
const m891 = msg(
  mf`Oled juba kasutaja?`,
  mf`Olet jo rekisteröitynyt`,
  mf`Уже являетесь пользователем?`,
)

export { m891 as 'w.register.welcome' }