import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Privaatsus ja andmekaitse

  <span style="color:#85c1dc;">**FI:**</span>
  Yksityisyys ja tietosuoja

  <span style="color:#85c1dc;">**RU:**</span>
  Конфиденциальность и защита данных
*/
/* @__NO_SIDE_EFFECTS__ */
const m1868 = msg(
  mf`Privaatsus ja andmekaitse`,
  mf`Yksityisyys ja tietosuoja`,
  mf`Конфиденциальность и защита данных`,
)

export { m1868 as 'w.footer.privacy' }