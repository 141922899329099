<template>
  <ClientOnly>
    <div v-if="lastViewedProducts.length" v-bind="$attrs" class="h-container">
      <h2 class="p-4 text-center">{{ t['w.product.last.viewed.products.title']() }}</h2>
      <div class="grid grid-cols-3 gap-2 xs:grid-cols-4 sm:grid-cols-6 md:grid-cols-8 xl:grid-cols-12">
        <NuxtLink v-for="lastViewedProduct in lastViewedProducts" :key="`last-viewed-product-${lastViewedProduct.id}`" :to="lastViewedProduct.url" class="m-2">
          <OnImg
            :src="lastViewedProduct.image"
            width="200"
            :alt="lastViewedProduct.title"
            :title="lastViewedProduct.title"
            loading="lazy"
            decoding="async"
            class="aspect-[4/3] h-auto w-full rounded-xl object-cover md:rounded"
          />
        </NuxtLink>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { useLastViewedProducts } from '~/composables/last-viewed-products'
import * as t from '~/messages'
import OnImg from '~/components/on-img/OnImg.vue'

const { lastViewedProducts } = useLastViewedProducts()
</script>
