import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Olen tutvunud ja nõustun järelmaksu <span style="color:#a6d189;">**{link}**</span>.

  <span style="color:#85c1dc;">**FI:**</span>
  Olen tutustunut <span style="color:#a6d189;">**{link}**</span> ja hyväksyn ne.

  <span style="color:#85c1dc;">**RU:**</span>
  Я прочитал и согласен по оплате в рассрочку <span style="color:#a6d189;">**{link}**</span>
*/
/* @__NO_SIDE_EFFECTS__ */
const m1297 = msg(
  mf`Olen tutvunud ja nõustun järelmaksu ${'link'}.`,
  mf`Olen tutustunut ${'link'} ja hyväksyn ne.`,
  mf`Я прочитал и согласен по оплате в рассрочку ${'link'}`,
)

export { m1297 as 'w.basket.after_payment.agree_to_terms' }