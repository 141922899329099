type LanguageIndex = 0 | 1 | 2

export const currentLanguage = (): LanguageIndex => useState<LanguageIndex>('selectedLanguage').value

/*
  Placeholders used for filling message templates. Record<string, never> is needed for empty objects, because Record<never, string> would accept an arbitrary object to be used.
  This is used to properly validate the messages and mark oversupply of object properties as erroneous. Technically we could also present them as warnings, but at the time of writing
  the IDE support for such feature seems nonexistent. Until then, try to keep parity between expected and given inputs to avoid accumulation of dead code / references.

  T is the list of valid keys that can be used for the object.
*/
type Placeholders<T extends string> = [T] extends [never] ? Record<string, never> : Record<T, unknown>

/*
  Message function type exposes two variants. For empty objects we tolerate 0 arguments, or an empty object in case of 1, marking `p` as optional.
  Otherwise it needs to contain a valid object containing all placeholders used across languages.

  Directly calling the message function returns always a string.
  Using the subproperty .tokens(p) function we can also receive an array of strings interpolated with placeholder values. The resulting type should display both
  string and the types used for values in `p`.

  T is the list of valid keys that can be used for the object.
*/
type Message<T extends string> = [T] extends [never]
  ? {
      (p?: Record<string, never>): string
      tokens(p?: Record<string, never>): readonly string[]
    }
  : {
      (p: Record<T, unknown>): string
      tokens<P extends Record<T, unknown>>(p: P & Record<Exclude<keyof P, T>, never>): readonly (string | P[keyof P])[]
    }

/**
  Converts a template string into a message function. This way we can have human-readable message syntax that is also exteremly compact when bundled in JavaScript.
  We even get type inference for the placeholders used for free.

  Consider the following example:
  const greeting = mf`Hello ${'subject'}!`
  console.log(greeting({ subject: 'world' })) // "Hello world!"
  console.log(greeting.tokens({ subject: 'planet' })) // ["Hello ", "planet", "!"]
*/
/* @__NO_SIDE_EFFECTS__ */
export const mf = <T extends string, P extends readonly T[]>(strings: TemplateStringsArray, ...placeholderKeys: P): Message<P[number]> => {
  const tokens = <TP extends Placeholders<T>>(p: TP): readonly (string | TP[keyof TP])[] => {
    const result: (string | TP[keyof TP])[] = []

    for (let i = 0; i < strings.length; i++) {
      if (i > 0) {
        result.push(p[placeholderKeys[i - 1]!])
      }

      result.push(strings[i]!)
    }

    return result
  }

  const defaultFn = (p: Placeholders<T>): string => {
    return tokens(p).join('')
  }

  defaultFn.tokens = tokens

  return defaultFn as Message<P[number]>
}

/**
  Message function proxy which disguises the given array of message functions as one merged message function (object key names are combined).
*/
/* @__NO_SIDE_EFFECTS__ */
export const msg = <T extends string, R = string extends T ? Message<never> : Message<T>>(...msgs: readonly (Message<T> | Message<never>)[]): R => {
  // @ts-expect-error It's quite tricky to get Record<string, never> and Record<string, unknown> to play nicely in this situation.
  // The pure JavaScript version of this is simple enough that we can simply ignore the type mismatch locally. However, if you are into
  // puzzles, feel free to fix it :-)
  const defaultFn = (p: Placeholders<T>) => msgs[currentLanguage()]!(p)

  // @ts-expect-error same as above
  defaultFn.tokens = (p: Placeholders<T>) => msgs[currentLanguage()]!.tokens(p)

  return defaultFn as R
}
