import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Küpsised

  <span style="color:#85c1dc;">**FI:**</span>
  Evästeet

  <span style="color:#85c1dc;">**RU:**</span>
  Печенье
*/
/* @__NO_SIDE_EFFECTS__ */
const m797 = msg(
  mf`Küpsised`,
  mf`Evästeet`,
  mf`Печенье`,
)

export { m797 as 'w.footer.cookies' }