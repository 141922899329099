import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Ees- ja perekonnanimi

  <span style="color:#85c1dc;">**FI:**</span>
  Etu- ja sukunimi

  <span style="color:#85c1dc;">**RU:**</span>
  Имя и фамилия
*/
/* @__NO_SIDE_EFFECTS__ */
const m1060 = msg(
  mf`Ees- ja perekonnanimi`,
  mf`Etu- ja sukunimi`,
  mf`Имя и фамилия`,
)

export { m1060 as 'w.register.label.full_name' }