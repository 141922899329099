import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sisselogimine Facebookiga ebaõnnestus. Proovi uuesti või korduva ebaõnnestumise korral võta ühendust klienditeenindusega.

  <span style="color:#85c1dc;">**FI:**</span>
  Kirjautuminen Facebookilla epäonnistui. Yritä uudelleen tai ota yhteyttä asiakaspalveluun toistuvien epäonnistumisten yhteydessä.

  <span style="color:#85c1dc;">**RU:**</span>
  Вход через Facebook не удался. Попробуйте снова или, в случае повторной неудачи, свяжитесь со службой поддержки.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1414 = msg(
  mf`Sisselogimine Facebookiga ebaõnnestus. Proovi uuesti või korduva ebaõnnestumise korral võta ühendust klienditeenindusega.`,
  mf`Kirjautuminen Facebookilla epäonnistui. Yritä uudelleen tai ota yhteyttä asiakaspalveluun toistuvien epäonnistumisten yhteydessä.`,
  mf`Вход через Facebook не удался. Попробуйте снова или, в случае повторной неудачи, свяжитесь со службой поддержки.`,
)

export { m1414 as 'w.login.fb.error.generic_login' }