import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kasutame küpsiseid teie sirvimiskogemuse parandamiseks, isikupärastatud reklaamide või sisu esitamiseks ja liikluse analüüsimiseks.

  <span style="color:#85c1dc;">**FI:**</span>
  Käytämme evästeitä parantaaksemme selauskokemustasi, toimittaaksemme räätälöityjä mainoksia tai sisältöä ja analysoidaksemme liikennettä.

  <span style="color:#85c1dc;">**RU:**</span>
  Мы используем файлы cookie, чтобы улучшить ваш опыт просмотра, предоставлять персонализированную рекламу или контент и анализировать трафик.
*/
/* @__NO_SIDE_EFFECTS__ */
const m658 = msg(
  mf`Kasutame küpsiseid teie sirvimiskogemuse parandamiseks, isikupärastatud reklaamide või sisu esitamiseks ja liikluse analüüsimiseks.`,
  mf`Käytämme evästeitä parantaaksemme selauskokemustasi, toimittaaksemme räätälöityjä mainoksia tai sisältöä ja analysoidaksemme liikennettä.`,
  mf`Мы используем файлы cookie, чтобы улучшить ваш опыт просмотра, предоставлять персонализированную рекламу или контент и анализировать трафик.`,
)

export { m658 as 'w.cookies.customize.privacy-page.text' }