import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  E-posti aadress

  <span style="color:#85c1dc;">**FI:**</span>
  Sähköposti

  <span style="color:#85c1dc;">**RU:**</span>
  Электронная почта
*/
/* @__NO_SIDE_EFFECTS__ */
const m1205 = msg(
  mf`E-posti aadress`,
  mf`Sähköposti`,
  mf`Электронная почта`,
)

export { m1205 as 'w.ostukorv.epost' }