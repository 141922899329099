import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Tellimused

  <span style="color:#85c1dc;">**FI:**</span>
  Tilaukset

  <span style="color:#85c1dc;">**RU:**</span>
  Заказы
*/
/* @__NO_SIDE_EFFECTS__ */
const m482 = msg(
  mf`Tellimused`,
  mf`Tilaukset`,
  mf`Заказы`,
)

export { m482 as 'w.account.menu.order_history' }