<template>
  <div class="flex flex-col gap-y-1">
    <div v-if="props.address">{{ props.address }}</div>
    <div v-if="props.fullName">{{ props.fullName }}</div>
    <div v-if="props.recipientName && props.fullName !== props.recipientName">{{ props.recipientName }}</div>
    <div v-if="props.email">{{ props.email }}</div>
    <div v-if="props.phone">{{ props.phone }}</div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  address?: string
  fullName?: string
  recipientName?: string
  email?: string
  phone?: string
}>()
</script>
