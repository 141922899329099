import { defineStore } from 'pinia'

export const useStore = defineStore('filters', {
  state() {
    return {
      appliedFilters: {},
      orderBy: '0',
      orderByContext: 'HALL',
    }
  },
  actions: {
    updateSelectedChoicesForGroup({ group, selectedChoices }) {
      const { [group]: _, ...others } = this.appliedFilters

      if (selectedChoices.length) {
        this.appliedFilters = { [group]: { choices: selectedChoices }, ...others }
      } else {
        this.appliedFilters = others
      }
    },
    resetFilters() {
      this.appliedFilters = {}
    },
    removeFilter({ group, item }) {
      this.updateSelectedChoicesForGroup({
        group,
        selectedChoices: this.appliedFilters[group].choices.filter((x) => x !== item),
      })
    },
    setOrderBy(newOrderBy) {
      this.orderBy = newOrderBy
    },
    setOrderByContext(newOrderByContext: 'DISCOUNT' | 'NEW' | 'SEARCH' | 'HALL', defaultValue: string) {
      if (this.orderByContext !== newOrderByContext) {
        this.orderByContext = newOrderByContext
        this.orderBy = defaultValue
      }
    },
    updateRangeChoice({ group, interval }) {
      const clone = { ...this.appliedFilters }

      clone[group] = { ...interval }

      this.appliedFilters = clone
    },
    removeRangeFilter({ group }) {
      const { [group]: _, ...others } = this.appliedFilters

      this.appliedFilters = others
    },
    initializeFromQueryParameters({ query }) {
      const appliedFilters = {}
      Object.keys(query).forEach((param) => {
        const match = param.match(/^f(\d+)$/)
        if (!match) {
          return
        }

        const filter = {}
        if (Array.isArray(query[param])) {
          filter.choices = query[param].map((x) => +x)
        } else if (query[param].includes(',')) {
          const str = query[param].split(',')
          filter.min = +str[0]
          filter.max = +str[1]
        } else {
          filter.choices = [+query[param]]
        }
        appliedFilters[+match[1]] = filter
      })

      this.appliedFilters = appliedFilters
    },
  },
  getters: {
    getAppliedFilterIdsForChoiceGroup: (state) => (groupId) => {
      const choices = []

      if (state.appliedFilters[groupId]) {
        choices.push(...state.appliedFilters[groupId].choices)
      }

      return choices
    },
    getAppliedFilterIdsForRange: (state) => (groupId) => {
      return state.appliedFilters[groupId]
    },
    getOrderBy: (state) => () => {
      return state.orderBy
    },
    getFilterParameters(state) {
      const parameters = {}

      for (const filter in state.appliedFilters) {
        if (state.appliedFilters[filter].choices) {
          parameters[`f${filter}`] = state.appliedFilters[filter].choices
        } else if (typeof state.appliedFilters[filter].min !== 'undefined') {
          parameters[`f${filter}`] = `${state.appliedFilters[filter].min},${state.appliedFilters[filter].max}`
        }
      }

      return parameters
    },
  },
})
