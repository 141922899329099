import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  /info/privaatsus-ja-andmekaitse

  <span style="color:#85c1dc;">**FI:**</span>
  /info/tietosuojaseloste

  <span style="color:#85c1dc;">**RU:**</span>
  /info/приватность\_и\_защита\_данных
*/
/* @__NO_SIDE_EFFECTS__ */
const m1071 = msg(
  mf`/info/privaatsus-ja-andmekaitse`,
  mf`/info/tietosuojaseloste`,
  mf`/info/приватность_и_защита_данных`,
)

export { m1071 as 'link.privacy.href' }