const STORE_VERSION_KEY = 'store-version'
const NUXT3_STORE_2023_VERSION_NR = 3

export const useGtm = () => {
  const {
    public: {
      scripts: {
        googleTagManager: { id: gtmId },
      },
    },
  } = useRuntimeConfig()

  return useScriptGoogleTagManager({
    id: gtmId,
  })
}

export const useGtmAppInit = () => {
  const { proxy } = useGtm()

  proxy.dataLayer.push({ [STORE_VERSION_KEY]: NUXT3_STORE_2023_VERSION_NR })

  const router = useRouter()

  // Following has been lifted and then simplified from gtm-support/vue-gtm, we might be able to simplify or
  // migrate to useScriptEventPage when consulting with people responsible for handling the event in GTM.
  router.afterEach(async (to) => {
    if (typeof to.name !== 'string') {
      return
    }

    const name: string = to.name

    const baseUrl: string = router.options?.history?.base ?? ''
    let fullUrl: string = baseUrl
    if (!fullUrl.endsWith('/')) {
      fullUrl += '/'
    }
    fullUrl += to.fullPath.startsWith('/') ? to.fullPath.substring(1) : to.fullPath

    proxy.dataLayer.push({
      event: 'content-view',
      'content-name': fullUrl,
      'content-view-name': name,
      [STORE_VERSION_KEY]: NUXT3_STORE_2023_VERSION_NR,
    })
  })
}
