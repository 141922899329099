import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Taotle järelmaksu

  <span style="color:#85c1dc;">**FI:**</span>
  Lähetä

  <span style="color:#85c1dc;">**RU:**</span>
  Запросить рассрочку платежа
*/
/* @__NO_SIDE_EFFECTS__ */
const m795 = msg(
  mf`Taotle järelmaksu`,
  mf`Lähetä`,
  mf`Запросить рассрочку платежа`,
)

export { m795 as 'w.basket.after_payment.submit_button' }