import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Isikukood

  <span style="color:#85c1dc;">**FI:**</span>
  Henkilötunnus

  <span style="color:#85c1dc;">**RU:**</span>
  Личный код
*/
/* @__NO_SIDE_EFFECTS__ */
const m1542 = msg(
  mf`Isikukood`,
  mf`Henkilötunnus`,
  mf`Личный код`,
)

export { m1542 as 'w.basket.after_payment.personal_code' }