<template>
  <div class="flex flex-col md:flex-row md:items-center">
    <div class="relative order-2 h-fit md:order-1 md:max-w-1/2 md:basis-1/2 md:pr-5">
      <div class="w-full pb-8 text-center">
        <div class="text-h2 font-light">{{ registerSuccess ? t['w.register.title_alt']() : t['w.register.title']() }}</div>
        <div class="mt-2 text-base">
          {{ registerSuccess ? t['w.register.welcome_alt']() : t['w.register.welcome']() }}
          <button v-if="!registerSuccess" class="text-text-strong underline hover:no-underline" @click="emit('loginClick')">{{ t['w.register.login-account']() }}</button>
        </div>
      </div>
      <RegisterForm v-if="!registerSuccess" @successful-register="onSuccessfulRegister" />
      <LoginForm v-else :is-forwarding="false" :additional-logins="false" :show-messages="false" />
    </div>
    <img class="order-1 mx-auto mb-6 hidden max-w-40 sm:block md:order-2 md:mb-0 md:max-w-1/2 md:basis-1/2 md:pl-5" :src="registerSuccess ? successImage : regularImage" />
  </div>
</template>

<script setup lang="ts">
import * as t from '~/messages'
import RegisterForm from '~/components/register/RegisterForm.vue'
import successImage from '~/assets/illustrations/register-success.svg'
import regularImage from '~/assets/illustrations/register.svg'

const registerSuccess = ref<boolean>(false)

const onSuccessfulRegister = (): void => {
  registerSuccess.value = true

  emit('successfulRegister')
}

const emit = defineEmits<{
  successfulRegister: []
  loginClick: []
}>()
</script>
