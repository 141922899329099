import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Tere <span style="color:#a6d189;">**{name}**</span>

  <span style="color:#85c1dc;">**FI:**</span>
  Hei <span style="color:#a6d189;">**{name}**</span>

  <span style="color:#85c1dc;">**RU:**</span>
  Здравствуйте, <span style="color:#a6d189;">**{name}**</span>
*/
/* @__NO_SIDE_EFFECTS__ */
const m158 = msg(
  mf`Tere ${'name'}`,
  mf`Hei ${'name'}`,
  mf`Здравствуйте, ${'name'}`,
)

export { m158 as 'w.account.greeting' }