import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Logi sisse Facebook-iga

  <span style="color:#85c1dc;">**FI:**</span>
  Kirjaudu Facebookilla

  <span style="color:#85c1dc;">**RU:**</span>
  Войти через Facebook
*/
/* @__NO_SIDE_EFFECTS__ */
const m1703 = msg(
  mf`Logi sisse Facebook-iga`,
  mf`Kirjaudu Facebookilla`,
  mf`Войти через Facebook`,
)

export { m1703 as 'w.global.facebook-login' }