import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Järelmaksu taotlemine

  <span style="color:#85c1dc;">**FI:**</span>
  

  <span style="color:#85c1dc;">**RU:**</span>
  Подача заявления о рассрочке
*/
/* @__NO_SIDE_EFFECTS__ */
const m370 = msg(
  mf`Järelmaksu taotlemine`,
  mf``,
  mf`Подача заявления о рассрочке`,
)

export { m370 as 'w.basket.after_payment_subtitle' }