import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Tagasi

  <span style="color:#85c1dc;">**FI:**</span>
  Takaisin

  <span style="color:#85c1dc;">**RU:**</span>
  Назад
*/
/* @__NO_SIDE_EFFECTS__ */
const m686 = msg(
  mf`Tagasi`,
  mf`Takaisin`,
  mf`Назад`,
)

export { m686 as 'w.header.menu.back' }