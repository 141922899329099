// https://tanstack.com/query/latest/docs/framework/vue/guides/ssr#nuxt-3
import type { DehydratedState, VueQueryPluginOptions } from '@tanstack/vue-query'
import { VueQueryPlugin, QueryClient, hydrate, dehydrate } from '@tanstack/vue-query'
import type { FetchError } from 'ofetch'

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxt) {
    const vueQueryState = useState<DehydratedState | null>('vue-query')

    // Modify your Vue Query global settings here
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 5000,
          retry(failureCount, error) {
            if (import.meta.server) {
              return false
            }

            const statusCode = (error as FetchError).statusCode ?? 0

            if (![408, 425, 429, 500, 502, 503, 504].includes(statusCode)) {
              return false
            }

            return failureCount < 3
          },
        },
      },
    })
    const options: VueQueryPluginOptions = { queryClient }

    nuxt.vueApp.use(VueQueryPlugin, options)

    if (import.meta.server) {
      nuxt.hooks.hook('app:rendered', () => {
        vueQueryState.value = dehydrate(queryClient)
      })
    }

    if (import.meta.client) {
      hydrate(queryClient, vueQueryState.value)
    }
  },
})
