import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Vaadatud tooted

  <span style="color:#85c1dc;">**FI:**</span>
  Katsotut tuotteet

  <span style="color:#85c1dc;">**RU:**</span>
  Просмотренные товары
*/
/* @__NO_SIDE_EFFECTS__ */
const m404 = msg(
  mf`Vaadatud tooted`,
  mf`Katsotut tuotteet`,
  mf`Просмотренные товары`,
)

export { m404 as 'w.account.menu.last' }