import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  €

  <span style="color:#85c1dc;">**FI:**</span>
  €

  <span style="color:#85c1dc;">**RU:**</span>
  €
*/
/* @__NO_SIDE_EFFECTS__ */
const m483 = msg(
  mf`€`,
  mf`€`,
  mf`€`,
)

export { m483 as 'w.global.currency-symbol' }