<template>
  <Button
    type="button"
    class="btn-icon btn-rounded"
    :class="[
      props.buttonStyle,
      {
        'px-4 text-base': props.hasLabel,
      },
    ]"
    :icon-class="props.isAdded ? 'text-icon-bright' : ''"
    :icon-name="props.isAdded ? 'heart-filled' : 'heart'"
    :aria-label="props.isAdded ? t['w.global.product.favourite_added']() : t['w.global.product.favourite']()"
    @click.prevent="onClick"
  >
    <template v-if="hasLabel" #default>
      <template v-if="isAdded">{{ t['w.global.product.favourite_added']() }}</template>
      <template v-else>
        <span class="hidden lg:block">{{ t['w.wishlist.add']() }}</span>
        <span class="block lg:hidden">{{ t['w.global.product.favourite']() }}</span>
      </template>
    </template>
  </Button>
</template>

<script setup lang="ts">
import { useLoginModalStore } from '~/stores/login-modal'
import Button from '~/components/button/Button.vue'
import type { WishlistAddRequest, WishlistAddResponse, WishlistRemoveRequest, WishlistRemoveResponse } from '~/types/on24-klient'
import * as t from '~/messages'

const loginModalStore = useLoginModalStore()

const props = withDefaults(
  defineProps<{
    isAdded: boolean
    id: number
    variationId?: number | null
    optionIds?: number[] | null
    hasLabel?: boolean
    buttonStyle?: string
  }>(),
  {
    isAdded: false,
    variationId: null,
    optionIds: null,
    hasLabel: false,
    buttonStyle: 'btn-wishlist',
  }
)

const user = useUserState()
const wishlist = useWishlistState()

const emit = defineEmits<{
  onAddedToWishlist: [wishlistItemId: number]
  onRemovedFromWishlist: []
}>()

const onClick = () => {
  if (props.isAdded) {
    removeFromWishlist()
  } else {
    addToWishlist()
  }
}

const removeFromWishlist = () => {
  const data: WishlistRemoveRequest = {
    productId: props.id,
    variationId: null,
    optionIds: null,
  }

  $fetch<WishlistRemoveResponse>('/api/account/wishlist/remove', {
    method: 'POST',
    body: data,
  }).then((response) => {
    wishlist.value = response.wishlistSize
    emit('onRemovedFromWishlist')
  })
}

const addToWishlist = () => {
  const loggedIn = !!user.value
  if (!loggedIn) {
    loginModalStore.open(t['w.login.wishlist_welcome']())
    loginModalStore.setQueuedWishlistItem(props.id, props.variationId, props.optionIds)
  }

  const data: WishlistAddRequest = {
    productId: props.id,
    variationId: props.variationId,
    optionIds: props.optionIds,
  }

  $fetch<WishlistAddResponse>('/api/account/wishlist/add', {
    method: 'POST',
    body: data,
  }).then((response) => {
    wishlist.value = response.wishlistSize

    if (response.newWishlistItemId === null) {
      return
    }

    emit('onAddedToWishlist', response.newWishlistItemId)
  })
}
</script>
