import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Makseviis

  <span style="color:#85c1dc;">**FI:**</span>
  Maksutapa

  <span style="color:#85c1dc;">**RU:**</span>
  Способ оплаты
*/
/* @__NO_SIDE_EFFECTS__ */
const m1135 = msg(
  mf`Makseviis`,
  mf`Maksutapa`,
  mf`Способ оплаты`,
)

export { m1135 as 'w.basket.payment.title' }