import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Registreeru

  <span style="color:#85c1dc;">**FI:**</span>
  Rekisteröidy

  <span style="color:#85c1dc;">**RU:**</span>
  Зарегистрироваться
*/
/* @__NO_SIDE_EFFECTS__ */
const m1607 = msg(
  mf`Registreeru`,
  mf`Rekisteröidy`,
  mf`Зарегистрироваться`,
)

export { m1607 as 'w.global.register' }