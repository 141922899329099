import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Kontakt ja abi

  <span style="color:#85c1dc;">**FI:**</span>
  Yhteystiedot ja neuvonta

  <span style="color:#85c1dc;">**RU:**</span>
  Контакты и помощь
*/
/* @__NO_SIDE_EFFECTS__ */
const m1482 = msg(
  mf`Kontakt ja abi`,
  mf`Yhteystiedot ja neuvonta`,
  mf`Контакты и помощь`,
)

export { m1482 as 'w.login.contact' }