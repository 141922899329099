import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Minu tellimused

  <span style="color:#85c1dc;">**FI:**</span>
  Tilaukseni

  <span style="color:#85c1dc;">**RU:**</span>
  Мои заказы
*/
/* @__NO_SIDE_EFFECTS__ */
const m1744 = msg(
  mf`Minu tellimused`,
  mf`Tilaukseni`,
  mf`Мои заказы`,
)

export { m1744 as 'w.login.my-orders' }