import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Funktsionaalsed

  <span style="color:#85c1dc;">**FI:**</span>
  Toiminnalliset

  <span style="color:#85c1dc;">**RU:**</span>
  Функциональные
*/
/* @__NO_SIDE_EFFECTS__ */
const m1413 = msg(
  mf`Funktsionaalsed`,
  mf`Toiminnalliset`,
  mf`Функциональные`,
)

export { m1413 as 'w.cookies.customize.functional.title' }