export const useAsklyChat = () => {
  const {
    public: {
      scripts: {
        askly: { clientKey },
      },
    },
  } = useRuntimeConfig()

  useScript({
    src: 'https://chat.askly.me/cw/chat/latest.js',
    crossorigin: false,
    // @ts-expect-error no type exists for arbitrary attribute names
    'tw-client-key': clientKey,
  })
}
