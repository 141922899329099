import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Tere,

  <span style="color:#85c1dc;">**FI:**</span>
  Hei,

  <span style="color:#85c1dc;">**RU:**</span>
  Здравствуйте,
*/
/* @__NO_SIDE_EFFECTS__ */
const m239 = msg(
  mf`Tere,`,
  mf`Hei,`,
  mf`Здравствуйте,`,
)

export { m239 as 'w.header.menu.greeting' }