import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sisesta oma parool

  <span style="color:#85c1dc;">**FI:**</span>
  Kirjoita salasanasi

  <span style="color:#85c1dc;">**RU:**</span>
  Введите ваш пароль
*/
/* @__NO_SIDE_EFFECTS__ */
const m632 = msg(
  mf`Sisesta oma parool`,
  mf`Kirjoita salasanasi`,
  mf`Введите ваш пароль`,
)

export { m632 as 'w.login.placeholder.password' }