import { defineStore } from 'pinia'
import type { WishlistAddRequest, WishlistAddResponse } from '~/types/on24-klient'

type View = 'login' | 'register'

type WishlistQueuedItem = {
  productId: number
  variationId: number | null
  optionIds: number[] | null
} | null

export const useLoginModalStore = defineStore('loginModal', {
  state() {
    return {
      isOpen: false,
      currentView: 'login' as View,
      text: '',
      queuedWishlistItem: null as WishlistQueuedItem,
    }
  },
  actions: {
    open(text: string | null, view: View = 'login') {
      this.isOpen = true
      this.text = text ?? this.text
      this.currentView = view
    },
    close() {
      this.isOpen = false
      this.queuedWishlistItem = null
    },
    setIsOpen(value: boolean) {
      this.isOpen = value

      if (!value) {
        this.queuedWishlistItem = null
      }
    },
    setCurrentView(value: View) {
      this.currentView = value
    },
    setQueuedWishlistItem(productId: number, variationId: number | null, optionIds: number[] | null) {
      this.queuedWishlistItem = {
        productId,
        variationId,
        optionIds,
      }
    },
    useQueuedWishlistItem() {
      if (this.queuedWishlistItem) {
        const data: WishlistAddRequest = {
          productId: this.queuedWishlistItem.productId,
          variationId: this.queuedWishlistItem.variationId,
          optionIds: this.queuedWishlistItem.optionIds,
        }

        $fetch<WishlistAddResponse>('/api/account/wishlist/add', {
          method: 'POST',
          body: data,
        })
        this.queuedWishlistItem = null
      }
    },
  },
  getters: {
    getIsOpen: (state) => () => {
      return state.isOpen
    },
    getText: (state) => () => {
      return state.text
    },
    getCurrentView: (state) => () => {
      return state.currentView
    },
  },
})
