import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Küpsised on vajalikud nii sulle kui ka meile

  <span style="color:#85c1dc;">**FI:**</span>
  Evästeet ovat välttämättömiä sekä sinulle että meille

  <span style="color:#85c1dc;">**RU:**</span>
  Файлы cookie необходимы и Вам, и нам
*/
/* @__NO_SIDE_EFFECTS__ */
const m293 = msg(
  mf`Küpsised on vajalikud nii sulle kui ka meile`,
  mf`Evästeet ovat välttämättömiä sekä sinulle että meille`,
  mf`Файлы cookie необходимы и Вам, и нам`,
)

export { m293 as 'w.cookies.preview.title' }