import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Üldtingimused

  <span style="color:#85c1dc;">**FI:**</span>
  Käyttöehdot

  <span style="color:#85c1dc;">**RU:**</span>
  Общие условия
*/
/* @__NO_SIDE_EFFECTS__ */
const m1823 = msg(
  mf`Üldtingimused`,
  mf`Käyttöehdot`,
  mf`Общие условия`,
)

export { m1823 as 'w.footer.conditions' }