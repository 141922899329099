import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Jäta mind meelde

  <span style="color:#85c1dc;">**FI:**</span>
  Muista minut

  <span style="color:#85c1dc;">**RU:**</span>
  Запомнить меня
*/
/* @__NO_SIDE_EFFECTS__ */
const m906 = msg(
  mf`Jäta mind meelde`,
  mf`Muista minut`,
  mf`Запомнить меня`,
)

export { m906 as 'w.klient.remember' }