import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Logi sisse siin

  <span style="color:#85c1dc;">**FI:**</span>
  Kirjaudu sisään tästä

  <span style="color:#85c1dc;">**RU:**</span>
  Войдите здесь
*/
/* @__NO_SIDE_EFFECTS__ */
const m1708 = msg(
  mf`Logi sisse siin`,
  mf`Kirjaudu sisään tästä`,
  mf`Войдите здесь`,
)

export { m1708 as 'w.register.login-account' }