import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Lemmikud

  <span style="color:#85c1dc;">**FI:**</span>
  Suosikkituotteet

  <span style="color:#85c1dc;">**RU:**</span>
  Избранные товары
*/
/* @__NO_SIDE_EFFECTS__ */
const m1675 = msg(
  mf`Lemmikud`,
  mf`Suosikkituotteet`,
  mf`Избранные товары`,
)

export { m1675 as 'w.wishlist.title' }