import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Isikuandmed

  <span style="color:#85c1dc;">**FI:**</span>
  Henkilökohtaisia ​​tietoja

  <span style="color:#85c1dc;">**RU:**</span>
  Личные данные
*/
/* @__NO_SIDE_EFFECTS__ */
const m1737 = msg(
  mf`Isikuandmed`,
  mf`Henkilökohtaisia ​​tietoja`,
  mf`Личные данные`,
)

export { m1737 as 'w.basket.after_payment.personal_details' }