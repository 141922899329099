import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Sinu ON24 raha:

  <span style="color:#85c1dc;">**FI:**</span>
  ON24-tilisi saldo

  <span style="color:#85c1dc;">**RU:**</span>
  Баланс на Вашем аккаунте:
*/
/* @__NO_SIDE_EFFECTS__ */
const m1552 = msg(
  mf`Sinu ON24 raha:`,
  mf`ON24-tilisi saldo`,
  mf`Баланс на Вашем аккаунте:`,
)

export { m1552 as 'w.account_menu.credit' }