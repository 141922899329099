<!--
  Animated collapsible where nested components will resize/take space appropriately.

  Uses technique from https://keithjgrant.com/posts/2023/04/transitioning-to-height-auto/#with-grid

  Per https://www.youtube.com/watch?v=B_n4YONte5A&lc=UgyVLIke6iGBtZmTfiZ4AaABAg (comment) also `grid-row: 1 / span 2;` is required
  on inner element to match the animation duration.
-->

<template>
  <CollapsibleRoot disabled :open="isOpen">
    <CollapsibleContent ref="content" class="grid data-[state=closed]:animate-collapsible-close-grid data-[state=open]:animate-collapsible-open-grid">
      <div class="row-[1_/_span_2] overflow-hidden">
        <slot />
      </div>
    </CollapsibleContent>
  </CollapsibleRoot>
</template>

<script setup lang="ts">
import { CollapsibleContent, CollapsibleRoot } from 'radix-vue'

const props = withDefaults(
  defineProps<{
    isOpen: boolean
  }>(),
  {
    isOpen: true,
  }
)

const content = useTemplateRef('content')

const onAnimationEnd = (event: AnimationEvent) => {
  if (event.target !== event.currentTarget) {
    return // Ignore child events
  }

  if (props.isOpen) {
    emit('afterOpen')
  } else {
    emit('afterClose')
  }
}

onMounted(() => {
  content.value?.$el.addEventListener('animationend', onAnimationEnd)
})

onUnmounted(() => {
  content.value?.$el.removeEventListener('animationend', onAnimationEnd)
})

const emit = defineEmits<{
  afterClose: []
  afterOpen: []
}>()
</script>
