import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Saatsime sinu e-posti aadressile salasõna.

  <span style="color:#85c1dc;">**FI:**</span>
  Lähetimme salasanan sähköpostiisi.

  <span style="color:#85c1dc;">**RU:**</span>
  Мы отправили пароль на Ваш адрес электронной почты.
*/
/* @__NO_SIDE_EFFECTS__ */
const m1436 = msg(
  mf`Saatsime sinu e-posti aadressile salasõna.`,
  mf`Lähetimme salasanan sähköpostiisi.`,
  mf`Мы отправили пароль на Ваш адрес электронной почты.`,
)

export { m1436 as 'w.register.welcome_alt' }