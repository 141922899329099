import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  /info/kontaktandmed

  <span style="color:#85c1dc;">**FI:**</span>
  /info/uutiset\_ja\_tiedotteet

  <span style="color:#85c1dc;">**RU:**</span>
  /info/контакт
*/
/* @__NO_SIDE_EFFECTS__ */
const m1491 = msg(
  mf`/info/kontaktandmed`,
  mf`/info/uutiset_ja_tiedotteet`,
  mf`/info/контакт`,
)

export { m1491 as 'w.login.contact.url' }