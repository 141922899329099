<template>
  <GenericDropdown :selected="isSelected" :dropdown-disabled="props.dropdownDisabled">
    <template #label>
      <div class="w-full">
        <button type="button" class="relative flex w-full items-center" @click="emit('click', $event)">
          <span
            class="relative ml-4 block size-4 shrink-0 rounded-full border border-border after:absolute after:inset-[3px] after:rounded-full"
            :class="{
              'after:bg-icon': isSelected,
            }"
          />
          <slot name="label" />
        </button>
      </div>
    </template>
    <template #content>
      <slot name="content" />
    </template>
  </GenericDropdown>
</template>

<script setup lang="ts">
import GenericDropdown from '~/components/order/GenericDropdown.vue'

const props = withDefaults(
  defineProps<{
    selected: boolean
    dropdownDisabled?: boolean
  }>(),
  {
    selected: false,
    dropdownDisabled: false,
  }
)

const isSelected = computed<boolean>(() => props.selected)

const emit = defineEmits<{
  click: [e: Event]
}>()
</script>
