import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Postiindeks

  <span style="color:#85c1dc;">**FI:**</span>
  Postinumero

  <span style="color:#85c1dc;">**RU:**</span>
  Почтовый индекс
*/
/* @__NO_SIDE_EFFECTS__ */
const m1427 = msg(
  mf`Postiindeks`,
  mf`Postinumero`,
  mf`Почтовый индекс`,
)

export { m1427 as 'w.basket.after_payment.postal_code' }