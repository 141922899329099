import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Analüütilised

  <span style="color:#85c1dc;">**FI:**</span>
  Analyyttiset

  <span style="color:#85c1dc;">**RU:**</span>
  Аналитические
*/
/* @__NO_SIDE_EFFECTS__ */
const m336 = msg(
  mf`Analüütilised`,
  mf`Analyyttiset`,
  mf`Аналитические`,
)

export { m336 as 'w.cookies.customize.analytical.title' }