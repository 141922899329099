import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Ostukorv

  <span style="color:#85c1dc;">**FI:**</span>
  Ostoslista

  <span style="color:#85c1dc;">**RU:**</span>
  Корзина
*/
/* @__NO_SIDE_EFFECTS__ */
const m329 = msg(
  mf`Ostukorv`,
  mf`Ostoslista`,
  mf`Корзина`,
)

export { m329 as 'w.menu.ostukorv' }