<template>
  <GenericDropdown :selected="isSelected" :dropdown-disabled="props.dropdownDisabled">
    <template #label>
      <div class="w-full">
        <input :id="`tv-${props.id}`" v-model="computedModelValue" :name="props.name" type="radio" class="sr-only" :value="props.id" />
        <label class="relative flex w-full cursor-pointer items-center" :for="`tv-${props.id}`">
          <span
            class="relative ml-4 block size-4 shrink-0 rounded-full border border-border after:absolute after:inset-[3px] after:rounded-full"
            :class="{
              'after:bg-icon': isSelected,
            }"
          />
          <slot name="label" />
        </label>
      </div>
    </template>
    <template #content>
      <slot name="content" />
    </template>
  </GenericDropdown>
</template>

<script setup lang="ts">
import GenericDropdown from '~/components/order/GenericDropdown.vue'

const props = withDefaults(
  defineProps<{
    id: string | number
    modelValue: number | string | null
    name: string
    dropdownDisabled?: boolean
  }>(),
  {
    dropdownDisabled: false,
  }
)

const isSelected = computed<boolean>(() => computedModelValue.value === props.id)

const computedModelValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const emit = defineEmits<{
  'update:modelValue': [newValue: number | string | null]
}>()
</script>
