import { defineStore } from 'pinia'

interface ContactInfo {
  address: string
  fullName: string
  recipientName: string
  email: string
  phone: string
}

export const useFormattedCheckoutStore = defineStore('formattedCheckoutData', {
  state() {
    return {
      selectedTransportType: '',
      contactInfo: {
        address: '',
        fullName: '',
        recipientName: '',
        email: '',
        phone: '',
      } as ContactInfo,
    }
  },
  actions: {
    setSelectedTransportType(value: string) {
      this.selectedTransportType = value
    },
    setContactInfo(value: ContactInfo) {
      this.contactInfo = value
    },
  },
  getters: {
    getSelectedTransportType: (state) => () => {
      return state.selectedTransportType
    },
    getContactInfo: (state) => () => {
      return state.contactInfo
    },
  },
})
