import { defineStore } from 'pinia'

export const useFacebookLoginErrorStore = defineStore('facebookLoginErrorStore', {
  state() {
    return {
      loginErrorMessage: '',
      registerErrorMessage: '',
    }
  },
  actions: {
    clearErrors() {
      this.loginErrorMessage = ''
      this.registerErrorMessage = ''
    },
    clearLoginError() {
      this.loginErrorMessage = ''
    },
    clearRegisterError() {
      this.registerErrorMessage = ''
    },
    setLoginErrorMessage(value: string) {
      this.loginErrorMessage = value
    },
    setRegisterErrorMessage(value: string) {
      this.registerErrorMessage = value
    },
  },
})
