import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Lemmikud

  <span style="color:#85c1dc;">**FI:**</span>
  Suosikit

  <span style="color:#85c1dc;">**RU:**</span>
  Избранное
*/
/* @__NO_SIDE_EFFECTS__ */
const m1356 = msg(
  mf`Lemmikud`,
  mf`Suosikit`,
  mf`Избранное`,
)

export { m1356 as 'w.account.menu.wishlist' }