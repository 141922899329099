import { mf, msg } from '../message-functions'

/**
  <span style="color:#85c1dc;">**EE:**</span>
  Konto andmed

  <span style="color:#85c1dc;">**FI:**</span>
  Tilin tiedot

  <span style="color:#85c1dc;">**RU:**</span>
  Данные аккаунта
*/
/* @__NO_SIDE_EFFECTS__ */
const m1806 = msg(
  mf`Konto andmed`,
  mf`Tilin tiedot`,
  mf`Данные аккаунта`,
)

export { m1806 as 'w.account.menu.main' }